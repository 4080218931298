.header-links {
  display: block;
  transition: color 200ms ease;
  color: #1d1d1d;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.header-links:hover {
  text-decoration: underline;
}

.additional-options-wrapper {
  border: 1px solid #e9e9e9;
  padding: 0 15px 0 15px;
  margin-bottom: 10px;
}
.additional-options-wrapper-landing-pages {
  border: 1px solid #e9e9e9;
  padding: 0 0px 0 0px;
  margin-bottom: 10px;
}

.additional-options {
  display: flex;
  height: 40px;
  min-width: 378px;
  align-items: center;
  border: none;
}

.additional-options:focus {outline:0;}

@media screen and (max-width: 479px) {
  .additional-options {
    min-width: 90vw !important;
  }
}

.summary-dropdowns {
  width: 170px;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: auto !important;
}

.search__indicators {
  display: none !important;
}

.search__value-container {
  height: 38px;
}

.nav-search-field input {
  width: 100%;
  outline: none;
  border: none;
}

.nav-search-field div {
  display: block !important;
}
