.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-commerce-commercecheckoutblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutblockcontent {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
}

.w-commerce-commercecheckoutcolumn {
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecheckoutshippingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecheckoutshippingstreetaddress {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingcity {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutrow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .w-commerce-commercecheckoutshippingstateprovince {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingzippostalcode {
    font-size: 16px;
  }
  .w-commerce-commercecheckoutshippingcountryselector {
    font-size: 16px;
  }

}

.container {
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.home-slider {
  background-color: #f9f7f6;
}

.main-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 0px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Questrial, sans-serif;
}

.footer {
  padding-top: 60px;
  padding-bottom: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: center;
}

.footer-links {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.footer-text {
  display: inline-block;
  margin-bottom: 10px;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  font-size: 14px;
  line-height: 22px;
}

.footer-2 {
  border-top: 1px solid #e9e9e9;
  background-color: #fff;
}

.footer-title {
  margin-bottom: 16px;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  cursor: pointer;
}

.brand {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.brand.footer {
  width: 120px;
  margin-bottom: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-top-style: none;
}

.filled-btn {
  margin-top: 16px;
  margin-right: 16px;
  padding: 12px 24px;
  border-radius: 0px;
  background-color: #1d1d1d;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);
  -webkit-transition: padding 200ms ease, background-color 200ms ease;
  transition: padding 200ms ease, background-color 200ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-decoration: none;
}

.filled-btn.search-btn {
  margin-top: 0px;
  margin-right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: Questrial, sans-serif;
  line-height: 26px;
  text-align: center;
}

.stripe-text {
  display: inline-block;
  margin-right: 8px;
  font-family: Questrial, sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}

.search-input-field {
  height: 46px;
  min-width: 20vw;
  margin-bottom: 0px;
  padding: 16px 24px 16px 10px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #1d1d1d #1d1d1d rgba(29, 29, 29, 0.3);
  border-radius: 0px;
  font-family: Questrial, sans-serif;
}

.search-input-field:focus {
  border-style: none none solid;
  border-color: #be4632 #be4632 #1d1d1d;
}

.copyright-text {
  display: inline-block;
  font-family: Questrial, sans-serif;
  color: rgba(29, 29, 29, 0.7);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
}

.copyright-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.copyright-block.align-right {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.stripe-logo {
  height: 24px;
}

.footer-categories-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-categories-wrapper.col {
  width: 33.333%;
}

.copyright-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 56px;
  padding: 24px 56px;
  border-top: 1px solid #e9e9e9;
  background-color: #fff;
}

.succes-state {
  margin-bottom: 10px;
  border: 2px solid #67cca0;
  background-color: transparent;
  color: #67cca0;
}

.footer-link-2 {
  display: block;
  margin-bottom: 10px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
}

.footer-link-2:hover {
  text-decoration: underline;
}

.newsletter-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.footer-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.footer-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 56px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.social-block {
  padding: 16px;
  opacity: 0.7;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.social-block:hover {
  opacity: 1;
}

.social-block.twitter {
  background-image: url(../assets/images/twitter-icon-dark.svg);
  background-position: 50% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
}

.social-block.insta {
  background-image: url(../assets/images/instagram-icon-dark.svg);
  background-position: 50% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
}

.social-block.fb {
  background-image: url(../assets/images/facebook-icon-dark.svg);
  background-position: 50% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
}

.error-state {
  margin-bottom: 10px;
  border: 2px solid #e84a5f;
  background-color: transparent;
  color: #e84a5f;
  text-align: center;
}

.footer-social-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1d1d1d;
}

.stripe-block {
  padding: 8px 16px;
  background-color: #1d1d1d;
}

.justbulklogofooter {
  margin-bottom: 10px;
}

.footer-cat-accordian-content {
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
}

.footer-category-links {
  display: block;
  margin-bottom: 10px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #1d1d1d;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
}

.footer-category-links:hover {
  text-decoration: underline;
}

.side-menu-category-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 70vh;
  padding-right: 16px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bodywrapper {
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  background-color: #fff;
}

.burger-wrap {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.menu-button {
  display: block;
  overflow: visible;
  margin-left: 0px;
  padding: 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.menu-button.w--open {
  background-color: transparent;
}

.burger-icon {
  width: 24px;
}

.close-menu-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 12px;
}

.back-icon {
  width: 20px;
}

.nav-link-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.nav-line {
  height: 2px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #1d1d1d;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.nav-logo-wrap {
  position: absolute;
  left: 0px;
  top: 90vh;
  right: 0px;
}

.nav-cart-contact-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: 6.3px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav-cart-trigger {
  padding: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
}

.nav-cart-icon {
  width: 28px;
}

.navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  padding-top: 3px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 86.7%, 0);
}

.justbulklogoheader {
  margin: 10px 20px 0px 1px;
  padding-top: 0px;
  padding-bottom: 42px;
  padding-left: 110px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-image: url('../assets/images/logo.svg');
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.nav-menu-2 {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 25vw;
  padding-top: 64px;
  padding-right: 10px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-right: 2px solid #1d1d1d;
  background-color: #fff;
}

.nav-link-2 {
  padding: 16px 0px 16px 20px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.nav-link-2.w--current {
  color: #1d1d1d;
  text-decoration: underline;
}

.logo-2 {
  position: relative;
  top: auto;
  bottom: auto;
  height: 40px;
}

.footer-title-newsletter {
  margin-bottom: 16px;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  cursor: pointer;
}

.search-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-search-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-search-field {
  width: auto;
  height: 40px;
  min-width: 35vw;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
}

.nav-search-button {
  height: 40px;
  margin-left: 5px;
  padding-right: 25px;
  padding-left: 25px;
  background-color: #000;
  font-family: Questrial, sans-serif;
}

.search-block {
  margin-top: 5px;
  margin-bottom: 5px;
}

.nav-cart-count-wrapper {
  width: 25px;
  height: 25px;
  margin-bottom: 11px;
  margin-left: -20px;
}

.nav-cart-circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #d40004;
}

.nav-cart-count {
  padding: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.nav-contact-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-phone-no {
  display: block;
  margin-bottom: 0px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
}

.nav-phone-no:hover {
  text-decoration: underline;
}

.nav-timing-text {
  display: block;
  margin-top: -5px;
  margin-bottom: 0px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: Questrial, sans-serif;
  color: #bf0000;
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
}

.nav-timing-text:hover {
  text-decoration: underline;
}

.nav-wrapper {
  border-top: 1px solid #e9e9e9;
  background-color: #fff;
}

.top-menu-wrapper {
  border-top: 1px solid #e9e9e9;
  background-color: #e9e9e9;
}

.top-menu-container {
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.top-menu-text-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: 6.3px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 22px;
}

.top-menu-text-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 3px;
  padding-bottom: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Questrial, sans-serif;
  font-size: 12px;
  line-height: 22px;
}

.link {
  color: #333;
  text-decoration: none;
}

.text-block-2 {
  display: none;
}

.text-block-3 {
  display: none;
}

.success-message {
  background-color: #dbdbdb;
}

.nav-container {
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.bread-crumb-wrapper {
  display: block;
  margin-top: 10px;
  border-top: 1px none #e9e9e9;
  background-color: #f7f7f7;
}

.bread-crumb-container {
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.bread-crumb-text-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: 6.3px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 22px;
}

.bread-crumb-text-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  font-size: 12px;
  line-height: 22px;
}

.sidemenu-category-title {
  display: block;
  margin-bottom: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  cursor: pointer;
}

.sidemenu-category-title:hover {
  text-decoration: underline;
}

.side-menu-sub-category-title {
  display: block;
  margin-bottom: 10px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: rgba(29, 29, 29, 0.6);
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
}

.side-menu-sub-category-title:hover {
  text-decoration: underline;
}

.sidemenu-sub-category-accordian {
  overflow: hidden;
  margin-left: 10px;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  text-align: left;
}

.side-menu-accordian-block {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.footer-category-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p1-product-section-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p1-productpicwrapper {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  text-align: center;
}

.p1---tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.p1---tablink {
  width: 63px;
  height: 72px;
  margin-bottom: 5px;
  padding: 0px;
}

.p1---tablink.w--current {
  height: 72px;
  margin-bottom: 5px;
  padding: 0px;
}

.p1---tabs-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 455px;
  height: 520px;
  margin-left: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p1---tabbed-picture-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p1---tabpane {
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
}

.image-2 {
  width: 100%;
  height: 100%;
}

.image-3 {
  width: 100%;
  height: 100%;
}

.image-4 {
  width: 100%;
  height: 100%;
}

.image-5 {
  width: 100%;
  height: 100%;
}

.image-6 {
  width: 100%;
  height: 100%;
}

.image-7 {
  width: 100%;
  height: 100%;
}

.p1---product-details-block {
  margin-left: 30px;
}

.p1---sku {
  color: #8e8e8e;
  text-decoration: none;
}

.p1---sku.w--current {
  color: #1d1d1d;
}

.p1---sku.inline {
  display: inline-block;
  margin-right: 10px;
}

.p1---sku.inline.small {
  margin-right: 5px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
}

.p1---sku-no {
  display: inline;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
}

.p1---product-title {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: Questrial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
}

.p1---product-price {
  font-family: Questrial, sans-serif;
  font-size: 24px;
  line-height: 30px;
  cursor: default;
}

.p1---product-price.product-page {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 400;
}

.p1---product-price.product-page.sale {
  margin-right: 10px;
  color: #616161;
  text-decoration: none;
}

.p1---in-stock-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p1---in-stock-dot {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 16px;
  background-color: #67cca0;
}
.p1---out-stock-dot {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 16px;
  background-color: #c62424;
}

.p1---in-stock-text {
  margin-right: 10px;
  color: #67cca0;
}

.p1---out-stock-text {
  margin-right: 10px;
  color: #c62424;
}

.p1---product-tick-block {
  margin-top: 5px;
}

.p1---product-tick-rows {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
}

.p1---product-tick-icon {
  margin-right: 10px;
}

.p1---product-tick-text {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
}

.p1---brief-product-details {
  max-width: 500px;
}

.p1---unit-price {
  font-family: Questrial, sans-serif;
  font-size: 24px;
  line-height: 30px;
  cursor: default;
}

.p1---unit-price.product-page {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 0px;
  font-size: 16px;
  font-weight: 400;
}

.p1---unit-symbol {
  font-family: Questrial, sans-serif;
  font-size: 24px;
  line-height: 30px;
  cursor: default;
}

.p1---unit-symbol.product-page {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
}

.p1---dropdown-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.p1---dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  min-width: 378px;
  margin-bottom: 10px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e9e9e9;
}

.p1---add-to-cart {
  height: 40px;
  margin-left: 0px;
  padding-right: 52.5px;
  padding-left: 52.5px;
  background-color: #000;
}

.p1---quote-button {
  margin-top: 5px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  background-color: #000;
}
@media screen and (max-width: 479px) {
  .p1---quote-button{
    margin-right: 10px;
  }
}

.dropdown-toggle-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  min-width: 250px;
  margin-bottom: 10px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e9e9e9;
}

.dropdown-toggle-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 378px;
}

.p1---dropdown-list {
  max-height: 100px;
}

.p1---dropdown-list.w--open {
  overflow: auto;
  max-height: 200px;
}

.dropdown-link {
  margin-bottom: 0px;
}

.p1---dropdown-list-2 {
  max-height: 250px;
}

.p1---dropdown-list-2.w--open {
  overflow: auto;
  max-height: 200px;
}

.p1---dropdown-list-3 {
  overflow: auto;
  max-height: 200px;
}

.p1---tab-link-block {
  z-index: 0;
  padding: 10px 41px 10px 16px;
  border-right: 1px solid #e9e9e9;
  background-color: transparent;
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-size: 24px;
  line-height: 30px;
  width: 100%;
  min-width: 416px;
  text-align: right;
}

.p1---tab-link-block:hover {
  opacity: 1;
}

.p1---tab-link-block.w--current {
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  opacity: 1;
  font-size: 22px;
}

.additional-info-wrapper {
  margin-top: 0px;
}

.p1---product-info-tabs-3 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p1---product-info-wrapper {
  max-width: 1280px;
  margin-top: 70px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 25px;
  border-top: 1px solid #e9e9e9;
}

.shipping-tab {
  margin-top: 10px;
}

.additional-info-row {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
}

.additional-info-value {
  display: inline-block;
  margin-right: 32px;
}

.additional-info-value.muted {
  margin-right: 4px;
  color: rgba(29, 29, 29, 0.7);
}

.additional-info-value.muted.space {
  margin-right: 10px;
}

.p1---product-tab-text {
  font-size: 22px;
}

.p1---tabs-content-product-info {
  margin-top: 10px;
}

.additional-info-title {
  display: inline-block;
  width: 25%;
  margin-right: 32px;
}

.p1---tab-titles {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.p1---recommended-products-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 50px;
  padding-top: 29px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e9e9e9;
}

.p1---recommended-prod-title-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p1---recommended-prod-title {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 30px;
  font-family: Questrial, sans-serif;
  font-size: 30px;
  line-height: 42px;
  font-weight: 400;
}

.p1---recommended-products-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p1---recommended-product-frame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-decoration: none;
  color: #333 !important;
}

.image-8 {
  width: auto;
  height: auto;
}

.p1---recommend-prod-description {
  max-width: 175px;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
  font-family: Questrial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.p1---quote-modal-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  width: 100%;
  height: 100%;
  max-width: none;
  padding-top: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.8);
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.p1---modal-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  height: auto;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.p1---modal-first-name {
  margin-left: 0px;
  font-family: Questrial, sans-serif;
}

.p1---modal-form {
  margin-top: 15px;
}

.p1---modal-close-text {
  margin-top: 15px;
  font-family: Questrial, sans-serif;
  font-size: 22px;
  line-height: 30px;
}

.p1---modalform-text-area {
  min-height: 100px;
  font-family: Questrial, sans-serif;
}

.p1---modal-last-name {
  font-family: Questrial, sans-serif;
}

.p1---modal-email {
  font-family: Questrial, sans-serif;
}

.p1---modal-phone-no {
  font-family: Questrial, sans-serif;
}

.p1---modal-submit-button {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #000;
}

.checkbox-label {
  font-family: Questrial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
}

.p1---modal-close-link {
  padding-top: 0px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #c50f0f;
}

.text-span {
  position: relative;
  left: 15px;
  top: 8px;
  padding-right: 0px;
}

.p2-faq-answer {
  padding-left: 16px;
}

.faq-list-item {
  margin-bottom: 24px;
}

.p3---sidebar-block {
  margin-bottom: 32px;
}

.p2---faq-question {
  margin-bottom: 0px;
  padding-left: 16px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.p2--faq-container {
  max-width: 1280px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p2---faq-grid {
  grid-column-gap: 56px;
  -ms-grid-columns: 25% 1fr;
  grid-template-columns: 25% 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p2---faq-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.p2---faq-sidebar-block {
  margin-bottom: 32px;
}

.p2---faq-sidebar-block.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p2---faq-sidebar-title {
  font-size: 30px;
  font-weight: 400;
}

.p2---faq-side-bar-link {
  display: inline-block;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #8e8e8e;
  font-size: 16px;
  text-decoration: none;
}

.p2---faq-side-bar-link:hover {
  color: #1d1d1d;
}

.p2---faq-side-bar-link.w--current {
  color: #1d1d1d;
  font-size: 16px;
  text-decoration: underline;
}

.p2---faq-block {
  margin-bottom: 80px;
  text-decoration: none;
}

.p2---faq-title {
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
}

.p2---faq-list-item {
  margin-bottom: 24px;
}

.p2---bottom-help-text {
  font-size: 18px;
  line-height: 24px;
}

.text-field-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.text-field-spacer {
  width: 48px;
}

.text-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
  font-size: 14px;
  line-height: 22px;
}

.text-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.text-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.text-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.text-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.text-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-field-label {
  font-weight: 400;
}

.contact-sidebar-title {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.sidebar-link {
  display: block;
  margin-bottom: 10px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #1d1d1d;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
}

.sidebar-link:hover {
  text-decoration: underline;
}

.p3---contact-container {
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p3---contact-grid {
  margin-top: 30px;
  grid-column-gap: 56px;
  -ms-grid-columns: 25% 1fr;
  grid-template-columns: 25% 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p3---contact-side-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.p3---contact-side-bar.seperator {
  max-width: none;
  min-width: auto;
  padding-right: 40px;
  padding-left: 0px;
  border-right: 1px solid #e9e9e9;
}

.p3---form-block-2 {
  margin-bottom: 0px;
  padding-top: 0px;
}

.p3---text-field-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
}

.p3---form-text-field-copy {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
  font-size: 16px;
  line-height: 22px;
}

.p3---form-text-field-copy:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p3---form-text-field-copy::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p3---form-text-field-copy:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p3---form-text-field-copy::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p3---form-text-field-copy::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p3---form-text-field-copy.min-height {
  min-height: 240px;
  background-color: transparent;
}

.p3---submit-button {
  margin-top: 16px;
  margin-right: 16px;
  padding: 12px 24px;
  border-radius: 0px;
  background-color: #1d1d1d;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);
  -webkit-transition: padding 200ms ease, background-color 200ms ease;
  transition: padding 200ms ease, background-color 200ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-decoration: none;
}

.p4--search-results-block {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e9e9e9;
}

.p4-search-wrapper {
  max-width: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p4-search-result {
  padding-top: 8px;
}

.p4-search-result-url {
  margin-bottom: 8px;
  font-size: 18px;
  text-decoration: underline;
}

.p4-search-result-title {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 26px;
  text-decoration: none;
}

.p4-page-title {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
  text-align: center;
}

.p5-checkout-wrapper {
  max-width: 1280px;
}

.p5-checkout-grid {
  margin-top: 40px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-flow: row;
  grid-column-gap: 40px;
  -ms-grid-columns: 1fr 0.55fr;
  grid-template-columns: 1fr 0.55fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p5-left-checkout-block {
  padding-top: 16px;
  padding-right: 32px;
  padding-left: 32px;
  background-color: #fff;
}

.muted-text {
  color: #8e8e8e;
  text-decoration: none;
}

.muted-text.w--current {
  color: #1d1d1d;
}

.checkout-content-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.select-field {
  height: 100%;
  margin-bottom: 0px;
  padding: 14px 16px 14px 14px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(29, 29, 29, 0.7);
  border-radius: 0px;
  background-color: #fff;
}

.p5-shipping-method-block {
  padding: 14px 24px;
  border: 1px solid #e9e9e9;
}

.p5-checkout-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.p5-shipping-description {
  margin-top: -4px;
  margin-bottom: 0px;
  margin-left: 30px;
  font-weight: 400;
}

.p5-shipping-price-span {
  margin-bottom: 0px;
  margin-left: 10px;
}

.p5-shipping-method-wrapper {
  padding-top: 10px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e9e9e9;
}

.p5-payment-wrapper {
  padding: 10px 24px 24px;
}

.p5-expiry-sec-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p5-expiry-block {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p5-sec-block {
  margin-left: 28px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p5-exp-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-exp-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-exp-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-exp-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-exp-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-exp-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-exp-field.version-2 {
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-expiry-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-expiry-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-expiry-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-expiry-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-expiry-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-expiry-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-expiry-field.version-2 {
  position: static;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-first-name-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-first-name-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-first-name-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-first-name-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-first-name-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-first-name-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-first-name-field.version-2 {
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-last-name-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-last-name-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-last-name-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-last-name-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-last-name-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-last-name-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-last-name-field.version-2 {
  position: static;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-first-name-block {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p5-last-name-block {
  margin-left: 28px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p5-name-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p5-city-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-city-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-city-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-city-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-city-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-city-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-city-field.version-2 {
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-state-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-state-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-state-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-state-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-state-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-state-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-state-field.version-2 {
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-zip-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-zip-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-zip-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-zip-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-zip-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-zip-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-zip-field.version-2 {
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-address-line-1-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-address-line-1-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-address-line-1-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-address-line-1-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-address-line-1-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-address-line-1-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-address-line-1-field.version-2 {
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-address-line-2-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-address-line-2-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-address-line-2-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-address-line-2-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-address-line-2-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-address-line-2-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-address-line-2-field.version-2 {
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-company-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-company-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-company-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-company-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-company-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-company-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-company-field.version-2 {
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.checkbox-label-2 {
  margin-left: 10px;
}

.p5-right-checkout-block {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
  z-index: 0;
  padding: 16px 32px;
  background-color: #fff;
}

.p5-cart-summary-block {
  overflow: auto;
  max-height: 250px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.p5-order-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.image-12 {
  width: 70px;
  height: 80px;
  padding-top: 0px;
}

.p5-summary-product-title {
  padding-right: 10px;
  padding-left: 10px;
}

.dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 34px;
  margin-top: 5px;
  margin-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.dropdown-toggle-3 {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.text-block-5 {
  padding-left: 10px;
  font-size: 12px;
}

.dropdown-list {
  margin-top: 6px;
}

.dropdown-list.w--open {
  margin-top: 80px;
}

.p5-subtotal-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p5-delivery-total-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p5-vat-total-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p5-grand-total-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

.p5-grand-total-title {
  padding-right: 10px;
  padding-left: 0px;
  font-size: 18px;
}

.p5-grandtotal-price {
  padding-right: 10px;
  padding-left: 0px;
  font-size: 18px;
}

.p5-order-summary-text {
  padding-right: 10px;
  padding-left: 0px;
}

.text-span-2 {
  color: #0e4d92;
  text-decoration: underline;
}

.e_login_errormessageblock {
  text-align: center;
}

.e_signup_errorblock {
  position: relative;
  top: -40px;
}

.e_signup_errormessageblock {
  text-align: center;
}

.e_signup_successblock {
  height: 80px;
  background-color: transparent;
}

.p5-name-wrapper-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.p5---bread-crumb-text-wrapper {
  position: relative;
  z-index: 1;
  display: none;
  margin-right: auto;
  margin-left: 6.3px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 22px;
}

.p5-grey-bodywrapper {
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  background-color: #f2f2f2;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.p3-page-title-copy {
  margin-top: 0px;
  margin-bottom: 30px;
  font-weight: 400;
}

.bread-crumb-wrapper-copy {
  display: block;
  margin-top: 10px;
  border-top: 1px none #e9e9e9;
  background-color: #f7f7f7;
}

.p5-left-block-header {
  margin-top: 10px;
  padding: 8px 0px;
  border-top-style: none;
  border-top-color: #e6e6e6;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}

.p5-customer-info-block {
  padding: 10px 24px 24px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #e6e6e6;
}

.p5-vat-button {
  background-color: #000;
}

.p5-address-block {
  padding: 10px 24px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #e6e6e6;
}

.p5-recipient-name {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-recipient-name:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-recipient-name::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-recipient-name:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-recipient-name::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-recipient-name::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-recipient-name.version-2 {
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-recipient-mobile-no {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-recipient-mobile-no:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-recipient-mobile-no::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-recipient-mobile-no:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-recipient-mobile-no::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-recipient-mobile-no::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-recipient-mobile-no.version-2 {
  position: static;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-customer-info-phone {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-customer-info-phone:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-customer-info-phone::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-customer-info-phone:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-customer-info-phone::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-customer-info-phone::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-customer-info-phone.version-2 {
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-customer-info-email {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-customer-info-email:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-customer-info-email::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-customer-info-email:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-customer-info-email::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-customer-info-email::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-customer-info-email.version-2 {
  position: static;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-vat-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-vat-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-vat-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-vat-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-vat-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-vat-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-vat-field.version-2 {
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-billing-address-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  padding-bottom: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid #e9e9e9;
  text-align: center;
}

.p5-pay-button {
  display: inline-block;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #000;
  text-align: center;
}

.p5-subtotal-price {
  padding-right: 10px;
  padding-left: 10px;
}

.p5-delivery-price {
  padding-right: 10px;
  padding-left: 10px;
}

.p5-vat-price {
  padding-right: 10px;
  padding-left: 10px;
}

.p6-login-signup-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-right: 50px;
  margin-left: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.p6-loginsignup-title {
  position: relative;
  left: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  margin-top: 25px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-right: 5px;
  padding-left: 0px;
  float: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border: 1px none #000;
  color: #000;
  font-size: 25px;
  font-weight: 400;
}

.p6-title-block {
  padding-left: 40px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.p6-form-containers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p6-login-block {
  position: static;
  left: 35%;
  top: 25%;
  width: auto;
  height: auto;
  margin-top: 30px;
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: 0px solid #000;
}

.p6-form-seperator {
  height: 380px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  border-style: none none none solid;
  border-width: 1px;
  border-color: #000 #000 #000 rgba(0, 0, 0, 0.3);
}

.p6-login-heading {
  position: relative;
  left: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 350px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  padding-right: 5px;
  padding-left: 0px;
  float: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  font-size: 20px;
  font-weight: 400;
}

.p6-login-form-block {
  display: block;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p6-login-form-sub-block {
  position: relative;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 350px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p6-login-email-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
  font-size: 14px;
  line-height: 22px;
}

.p6-login-email-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p6-login-email-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-login-email-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-login-email-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-login-email-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-login-password-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
  font-size: 14px;
  line-height: 22px;
}

.p6-login-password-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p6-login-password-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-login-password-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-login-password-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-login-password-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-login-button {
  position: relative;
  display: inline-block;
  width: 250px;
  height: 40px;
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1.45px none #0e4d92;
  border-radius: 0px;
  background-color: #000;
  color: #fff;
}

.p6-forgot-password-block {
  position: relative;
  top: 5px;
  display: block;
  margin-top: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: grey;
  font-size: 12px;
  text-align: center;
}

.p6-signup-block {
  position: static;
  left: 35%;
  top: 21%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 350px;
  height: 400px;
  margin-right: 20px;
  margin-bottom: 25px;
  margin-left: 20px;
  padding-top: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  border: 1px none #000;
}

.p6-singup-title {
  position: relative;
  left: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 350px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  padding-right: 7px;
  padding-left: 0px;
  float: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  font-size: 20px;
  font-weight: 400;
}

.p6-singup-first-name-field {
  height: 48px;
  margin-right: 10px;
  margin-bottom: 32px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
  font-size: 14px;
  line-height: 22px;
}

.p6-singup-first-name-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p6-singup-first-name-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-singup-first-name-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-singup-first-name-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-singup-first-name-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-last-name-field {
  height: 48px;
  margin-right: 0px;
  margin-bottom: 32px;
  margin-left: 10px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
  font-size: 14px;
  line-height: 22px;
}

.p6-signup-last-name-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p6-signup-last-name-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-last-name-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-last-name-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-last-name-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-email-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
  font-size: 14px;
  line-height: 22px;
}

.p6-signup-email-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p6-signup-email-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-email-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-email-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-email-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-password-field {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
  font-size: 14px;
  line-height: 22px;
}

.p6-signup-password-field:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p6-signup-password-field::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-password-field:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-password-field::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-password-field::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p6-signup-button {
  position: static;
  top: -55px;
  display: inline-block;
  width: 250px;
  height: 40px;
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1.45px none #0e4d92;
  border-radius: 0px;
  background-color: #000;
  color: #fff;
}

.p6-signup-terms {
  position: static;
  left: 50px;
  top: -55px;
  display: block;
  width: 250px;
  margin-top: 12px;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  color: grey;
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  text-align: center;
}

.p6-signup-form {
  display: block;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p6-signup-form-block {
  position: relative;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 350px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
}

.p6-name-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.p7-checkout-wrapper {
  max-width: 1280px;
}

.p7-order-grid {
  margin-top: 40px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-flow: row;
  grid-column-gap: 40px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p7-left-checkout-block {
  padding-top: 16px;
  padding-right: 32px;
  padding-left: 32px;
  background-color: #fff;
}

.p7-left-block-header {
  margin-top: 10px;
  padding: 8px 0px;
  border-top-style: none;
  border-top-color: #e6e6e6;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}

.p7-customer-info-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-weight: 400;
  -o-object-fit: fill;
  object-fit: fill;
}

.p7-customer-info-wrapper {
  padding: 10px 24px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #e6e6e6;
}

.p7-confirmation-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.p7-address-details-text {
  font-weight: 400;
}

.p7-shipping-method-block {
  padding: 14px 24px;
  border: 1px none #e9e9e9;
}

.p7-shipping-method-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
}

.p7-payment-wrapper {
  padding: 10px 24px;
}

.p7-product-name-title {
  padding-right: 10px;
  padding-left: 10px;
}

.p7-product-varient {
  padding-right: 10px;
  padding-left: 10px;
}

.text-block-5-copy {
  padding-left: 10px;
  font-size: 12px;
}

.p7-product-quantity-selected {
  padding-left: 10px;
}

.p5-order-item-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 20px;
}

.p7-order-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 20px;
}

.p7-checkout-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.p7-right-checkout-block {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
  z-index: 0;
  padding: 16px 32px;
  background-color: #fff;
}

.p7-cart-summary-block {
  overflow: visible;
  margin-bottom: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.p7-checkout-title-copy {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.p7-subtotal-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p7-delivery-total-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p7-vat-total-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p7-subtotal-price-span {
  padding-right: 10px;
  padding-left: 10px;
}

.p7-delivery-price-span {
  padding-right: 10px;
  padding-left: 10px;
}

.p7-vat-price-span {
  padding-right: 10px;
  padding-left: 10px;
}

.p7-grandtotal-price {
  padding-right: 10px;
  padding-left: 0px;
  font-size: 18px;
}

.p7-order-confirmation-alert {
  max-width: 960px;
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  border: 2px solid #67cca0;
  background-color: #67cca0;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.4px;
}

.p7-download-pdf-button {
  display: inline-block;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #000;
  text-align: center;
}

.p7-product-price-span {
  padding-left: 10px;
}

.p5-product-price-span {
  padding-left: 10px;
}

.p5-summary-quantity {
  padding-right: 10px;
  padding-left: 10px;
}

.p8-category-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p8-left-wrapper {
  margin-right: 25px;
}

.p8-left-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: auto;
  padding-right: 16px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.p8-cat-title {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-right: 20px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-align: right;
}

.p8-side-menu-accordian-block {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.p8-sidemenu-category-title {
  display: block;
  margin-bottom: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Questrial, sans-serif;
  color: #1d1d1d;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  cursor: pointer;
}

.p8-sidemenu-category-title:hover {
  text-decoration: underline;
}

.p8-side-menu-sub-category-title {
  display: block;
  margin-bottom: 10px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: rgba(29, 29, 29, 0.5);
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: right;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
}

.p8-side-menu-sub-category-title:hover {
  text-decoration: underline;
}

.p8---side-bar-copy {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.p8---side-bar-copy.seperator {
  max-width: 280px;
  min-width: 280px;
  padding-right: 8px;
  border-right: 1px solid #e9e9e9;
}

.p8---product-frame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p8---product-title {
  max-width: 210px;
  margin-top: 3px;
  margin-bottom: 2px;
  padding: 7px 5px 7px 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-family: Questrial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #2e2e2e;
}

.p8---product-title:hover {
  text-decoration: underline !important;
}

.p8---product-price {
  max-width: none;
  padding: 1px 5px 5px 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  font-family: Questrial, sans-serif;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
}

.p8---product-price:hover {
  border-style: none;
}

.p8---sidebar-block {
  margin-bottom: 32px;
}

.p9-blog-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p9-left-wrapper {
  margin-right: 25px;
}

.p9---side-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.p9---side-bar.seperator {
  max-width: 280px;
  min-width: 280px;
  padding-right: 8px;
  border-right: 1px solid #e9e9e9;
}

.p9---sidebar-block {
  margin-bottom: 32px;
}

.p9-cat-title {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-right: 20px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-align: right;
}

.p9-left-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: auto;
  padding-right: 26px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.p9---blog-menu-frame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p9---blog-title {
  margin-top: 3px;
  margin-bottom: 2px;
  padding: 7px 5px 7px 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-family: Questrial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-decoration: none;
  color: #2e2e2e;
}

.p9---blog-title:hover {
  text-decoration: underline;
}

.p9---blog-date {
  max-width: none;
  padding: 1px 5px 5px 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  font-family: Questrial, sans-serif;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
}

.p9---blog-date:hover {
  border-style: none;
}

.p8-product-grid {
  grid-auto-flow: row;
  grid-column-gap: 0px;
  -ms-grid-columns: minmax(180px, 3fr) minmax(180px, 3fr) minmax(180px, 3fr) minmax(180px, 3fr);
  grid-template-columns: minmax(180px, 3fr) minmax(180px, 3fr) minmax(180px, 3fr) minmax(180px, 3fr);
}

.p9-grid {
  grid-auto-flow: row;
  grid-column-gap: 0px;
  -ms-grid-columns: minmax(180px, 2.5fr) minmax(180px, 2.5fr) minmax(180px, 2.5fr);
  grid-template-columns: minmax(180px, 2.5fr) minmax(180px, 2.5fr) minmax(180px, 2.5fr);
}

.p9-side-menu-category-links {
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  text-decoration: none;
}

.p9-left-contact-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: auto;
  padding-right: 26px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.text-block-6 {
  margin-top: 33px;
  padding-right: 20px;
  text-align: right;
}

.p6-text-block-6 {
  margin-top: 33px;
  padding-right: 20px;
  text-align: right;
}

.image-13 {
  width: auto;
  height: auto;
  margin-bottom: 20px;
  margin-left: 20px;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-2 {
  margin-left: 18px;
}

.p9-featured-title {
  max-width: 650px;
  margin-top: 0px;
  margin-bottom: 35px;
  padding-right: 20px;
  font-size: 36px;
  line-height: 45px;
  font-weight: 400;
  text-align: left;
}

.p10---cart-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9998;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  max-width: none;
  padding-top: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  background-color: hsla(0, 0%, 100%, 0.8);
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
  /*text-align: center;*/
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.p10---modal-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 550px;
  height: auto;
  margin-top: 0px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0px;
  background-color: #fff;
}

.p10---modal-close-link {
  padding-top: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #c50f0f;
}

.p10---modal-close-text {
  margin-top: 15px;
  font-family: Questrial, sans-serif;
  font-size: 22px;
  line-height: 30px;
}

.p10-cart-block-copy {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
  z-index: 0;
  overflow: visible;
  margin-top: 15px;
  padding: 16px 32px 0px;
  background-color: #fff;
}

.p10-checkout-title-copy {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-align: left;
}

.p10-cart-summary-block-copy {
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 17px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  -o-object-fit: fill;
  object-fit: fill;
}

.p10-order-item-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p10-checkout-button {
  display: inline-block;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #000;
  text-align: center;
}

.p10-product-info-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.p10-product-text {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: left;
}

.div-block-3 {
  width: 100%;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.p10-product-text-block {
  width: auto;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.p10-product-item-price {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  text-align: left;
}

.p10-item-price-block {
  position: static;
  top: -70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -70px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.p10-remove-item {
  padding-right: 10px;
  color: #333;
  font-size: 13px;
}

.p10-product-quantity-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 34px;
  margin-top: 5px;
  margin-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p10-dropdown-toggle-3-copy {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.p10-dropdown-list-copy {
  margin-top: 6px;
}

.p10-dropdown-list-copy.w--open {
  margin-top: 155px;
}

.p10-text-block-5-copy {
  padding-left: 10px;
  font-size: 12px;
}

.p10-close-text-span-copy {
  position: static;
  left: 0px;
  top: 24px;
  padding-right: 0px;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p10-product-item-block1 {
  margin-bottom: 30px;
}

.p10-product-item-block1-copy2 {
  margin-bottom: 30px;
}

.p10-product-item-block1-copy {
  margin-bottom: 30px;
}

.p10-product-item-block-new {
  margin-bottom: 30px;
}

.p11-invoice-wrapper {
  width: 595px;
  height: 802px;
  margin: 0px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border-style: solid solid none;
  border-width: 1px;
  border-color: #000;
}

.p11-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p11-address-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.p11-items-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
}

.p11-totals-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p11-footer-wrapper {
  position: static;
  left: 0%;
  top: 167px;
  right: 0%;
  bottom: 0%;
  z-index: auto;
  width: 595px;
}

.p11-invoice-details-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p11-bill-to-block {
  min-height: 80px;
  min-width: 150px;
  padding: 10px;
  border: 1px solid #000;
}

.p11-ship-to-block {
  min-height: 80px;
  min-width: 150px;
  padding: 10px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p11-product-code-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px solid #000;
}

.p11-item-des-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p11-quantity-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p11-unit-price-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p11-vat-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p11-total-price-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p11-notes-block {
  max-width: 275px;
  min-width: 275px;
}

.p11-totals-block {
  position: relative;
  left: -20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
}

.p11-column-title-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 20px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p11-product-title {
  padding-right: 20px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-product-title-copy {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-product-title-copy {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-product-title-copy {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-product-title-copy {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-product-title-copy {
  max-width: 200px;
  min-width: 200px;
  padding-right: 20px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-total-price-title {
  max-width: 76px;
  min-width: 76px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-vat-title {
  max-width: 41px;
  min-width: 41px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-unit-price-title {
  max-width: 62px;
  min-width: 62px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-quantity-title {
  max-width: 60px;
  min-width: 60px;
  padding-right: 7px;
  padding-left: 7px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-product-code-title {
  max-width: 85px;
  min-width: 85px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-product-code-column-copy1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid;
  border-width: 1px;
  border-color: #000;
}

.p11-order-product-code-block {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid;
  border-width: 1px;
  border-color: #000;
}

.p11-order-subblock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p11-order-product-code {
  max-width: 85px;
  min-width: 85px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 11px;
}

.p11-order-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
}

.p11-order-description-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px none #000;
  border-right: 1px solid #000;
  border-bottom: 1px none #000;
}

.p11-order-description-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p11-order-description {
  max-width: 200px;
  min-width: 200px;
  padding-right: 0px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 11px;
}

.p11-order-quantity-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none #000;
  border-right: 1px solid #000;
  border-bottom: 1px none #000;
}

.p11-order-quantity-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p11-order-quantity {
  max-width: 60px;
  min-width: 60px;
  padding-right: 7px;
  padding-left: 7px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 11px;
}

.p11-order-unit-price-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none #000;
  border-right: 1px solid #000;
  border-bottom: 1px none #000;
}

.p11-order-unit-price-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p11-order-unit-price {
  max-width: 62px;
  min-width: 62px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 11px;
}

.p11-order-vat-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none #000;
  border-right: 1px solid #000;
  border-bottom: 1px none #000;
}

.p11-order-vat-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p11-order-vat {
  max-width: 41px;
  min-width: 41px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 11px;
}

.p11-order-total-price-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none #000;
  border-right: 1px solid #000;
  border-bottom: 1px none #000;
}

.p11-order-total-price-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p11-order-total-price {
  max-width: 76px;
  min-width: 76px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 11px;
}

.p11-bottom-line {
  border-top: 1px solid #000;
}

.p11-billing-company-name {
  font-size: 11px;
  line-height: 14px;
}

.p11-billing-address-line-1 {
  font-size: 11px;
  line-height: 14px;
}

.p11-billing-address-line-2 {
  font-size: 11px;
  line-height: 14px;
}

.p11-billing-town-city {
  font-size: 11px;
  line-height: 14px;
}

.p11-billing-postal-code {
  font-size: 11px;
  line-height: 14px;
}

.p11-billing-country {
  font-size: 11px;
  line-height: 14px;
}

.bill-to-title {
  font-size: 12px;
  line-height: 16px;
}

.ship-to-title {
  font-size: 12px;
  line-height: 16px;
}

.p11-bill-to-title {
  font-size: 12px;
  line-height: 16px;
}

.p11-ship-to-title {
  font-size: 12px;
  line-height: 16px;
}

.p11-shipping-company-name {
  font-size: 11px;
  line-height: 14px;
}

.p11-shipping-address-line-1 {
  font-size: 11px;
  line-height: 14px;
}

.p11-shipping-address-line-2 {
  font-size: 11px;
  line-height: 14px;
}

.p11-shipping-town-city {
  font-size: 11px;
  line-height: 14px;
}

.p11-shipping-postal-code {
  font-size: 11px;
  line-height: 14px;
}

.p11-shipping-country {
  font-size: 11px;
  line-height: 14px;
}

.p11-notes-title {
  font-size: 12px;
}

.p11-notes {
  font-size: 10px;
  line-height: 14px;
}

.p11-totals-figure-wrapper {
  display: block;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-right: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.image-14 {
  width: 100px;
}

.p11-company-details {
  padding-top: 5px;
  font-size: 12px;
  line-height: 14px;
}

.p11-total-titles {
  margin-right: 10px;
  margin-left: 0px;
  font-size: 11px;
  line-height: 18px;
  text-align: right;
}

.text-block-7 {
  font-size: 12px;
}

.p11-gross {
  font-size: 11px;
  line-height: 18px;
}

.p11-delivery-charges {
  font-size: 11px;
  line-height: 18px;
}

.p11-vat-amount {
  font-size: 11px;
  line-height: 18px;
}

.p11-net-amount {
  font-size: 11px;
  line-height: 18px;
}

.p11-amount-paid {
  font-size: 11px;
  line-height: 18px;
}

.p11-balance {
  font-size: 11px;
  line-height: 18px;
}

.p11-invoice-no {
  font-size: 11px;
  line-height: 16px;
}

.p11-order-no {
  font-size: 11px;
  line-height: 16px;
}

.p11-invoice-date {
  font-size: 11px;
  line-height: 16px;
}

.p11-billing-date {
  font-size: 11px;
  line-height: 16px;
}

.p11-account-no {
  font-size: 11px;
  line-height: 16px;
}

.p11-currency-gbp {
  font-size: 11px;
  line-height: 16px;
}

.p11-thankyou-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
}

.text-block-8 {
  font-size: 12px;
  text-align: left;
}

.image-15 {
  margin-left: 2px;
  padding-top: 1px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.p11-block-block {
  height: 20px;
  background-color: #000;
}

.p11-black-block-text {
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  color: #fff;
  font-size: 11px;
  text-align: center;
}

.p11-thankyou-text {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-size: 12px;
  text-align: left;
}

.text-block-8-copy {
  font-size: 12px;
  text-align: left;
}

.p11-thankyou-com {
  padding-top: 0px;
  padding-left: 0.5px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-size: 12px;
  text-align: left;
}

.p12-left-wrapper {
  max-width: 800px;
  margin-right: 20px;
  margin-left: 0px;
}

.p12-right-wrapper {
  width: auto;
  max-width: 180px;
  margin-left: 20px;
}

.p12-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.p12-h1 {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
}

.p12-h2 {
  width: 90%;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.button {
  background-color: #000;
}

.p12-button {
  background-color: #000;
}

.p12-button-1 {
  margin-right: 10px;
  background-color: #000;
  line-height: 18px;
}

.p12-title-block {
  max-width: 800px;
  min-width: 0px;
  margin-bottom: 10px;
}

.p12-header-picture-block {
  margin-top: 5px;
}

.p12-product-summary-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.p12-product-summary-text-block {
  max-width: 800px;
  margin-left: 20px;
}

.image-16 {
  height: 200px;
}

.p12-h3 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 400;
}

.p12-product-summary-text {
  margin-left: 10px;
}

.p12-bullet-point-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p12-bullet-point {
  width: 10px;
  height: 10px;
  margin-top: 4px;
  padding: 5px;
  border-radius: 20px;
  background-color: #000;
}

.p12-snippet-text {
  line-height: 16px;
}

.p12-snippet-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.p12-snippet-button {
  min-width: 150px;
  margin-left: 20px;
  background-color: #000;
  line-height: 12px;
  text-align: center;
}

.slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.right-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p12-side-product-block {
  width: 170px;
  margin-bottom: 5px;
  padding-top: 5px;
  text-align: center;
}

.p12-side-product-title {
  width: auto;
  color: #333;
  text-align: center;
  text-decoration: none;
}

.image-17 {
  width: auto;
  margin-bottom: 10px;
}

.p12-side-product-image {
  width: auto;
  max-width: 100%;
  margin-bottom: 5px;
}

.p12-side-block-heading {
  margin-top: 30px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
}

.p12-paragraph-block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-block-9 {
  width: 90%;
}

.p12-section-title {
  display: block;
  margin-bottom: 0px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 20px;
  font-weight: 400;
}

.p12-product-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.p12-product-block {
  width: 180px;
  margin-right: 20px;
  margin-bottom: 5px;
  padding-top: 5px;
  text-align: center;
}

.p12-faq-text {
  width: 90%;
}

.p12-faq-text-block {
  margin-top: 5px;
  margin-bottom: 20px;
}

.p12-form {
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p12-form-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p12-form-field {
  width: 133px;
  margin-right: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.p12-form-para {
  width: 90%;
}

.p12-form-submit-button {
  height: 39px;
  min-width: 150px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #000;
  line-height: 16px;
}

.p12-recommended-product-text {
  margin-bottom: 5px;
}

.grid-2 {
  -ms-grid-columns: minmax(180px, 0.75fr) 1fr 1fr 1fr;
  grid-template-columns: minmax(180px, 0.75fr) 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p13-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  margin-top: 20px;
}

.p13-header-menu {
  height: auto;
  max-height: none;
  min-height: 230px;
  min-width: 220px;
  margin-right: 15px;
  padding: 10px;
  background-color: #e9e9e9;
}

.p13-banner-block {
  width: auto;
  height: auto;
  max-width: 1000px;
}

.p13-top-snippet-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-top: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000000;
}

.p13-personal-bulk-tab {
  margin-right: 5px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #fff;
}

.p13-personal-bulk-tab.w--current {
  margin-right: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border: 0.5px solid #000;
  background-color: #fff;
}

.p13-business-bulk {
  padding-right: 10px;
  padding-left: 10px;
  border: 1px none #000;
  background-color: #fff;
}

.tab-pane-tab-1 {
  overflow: visible;
  max-height: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
}

.p13-header-menu-category-block {
  overflow: auto;
  height: 180px;
  max-height: none;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  background-color: #fff;
}

.p13-header-menu-category-title {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
  color: #2e2e2e;
  text-decoration: none;
}

.p13-header-menu-category-title:hover {
  text-decoration: underline;
}

.image-19 {
  height: auto;
  max-height: 230px;
}

.p12-grid-2-copy {
  -ms-grid-columns: minmax(180px, 0.75fr) 1fr 1fr 1fr;
  grid-template-columns: minmax(180px, 0.75fr) 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p13-grid {
  margin-top: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  justify-items: center;
  -ms-grid-columns: minmax(180px, 0.75fr) minmax(180px, 0.75fr) minmax(180px, 0.75fr) minmax(180px, 0.75fr) minmax(180px, 0.75fr);
  grid-template-columns: minmax(180px, 0.75fr) minmax(180px, 0.75fr) minmax(180px, 0.75fr) minmax(180px, 0.75fr) minmax(180px, 0.75fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p13-product-block {
  width: 180px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 0px;
  padding-top: 5px;
  text-align: center;
}

.p13-split-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p13-left-wrapper {
  max-width: none;
  margin-right: 10px;
  margin-left: 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p13-right-wrapper {
  width: auto;
  max-width: 280px;
  margin-top: 50px;
  margin-left: 10px;
  padding-left: 15px;
  border-style: none none none solid;
  border-width: 1px;
  border-color: #000 #000 #000 rgba(0, 0, 0, 0.2);
}

.p13-side-block-heading {
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
}

.p13-side-blog-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-width: none;
  margin-bottom: 15px;
  padding-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: center;
}

.p13-top-product-image {
  width: auto;
  max-width: 100%;
  margin-bottom: 5px;
}

.p13-top-product-title {
  width: auto;
  color: #333;
  text-align: center;
  text-decoration: none;
}

.p13-top-product-title:hover {
  text-decoration: underline;
}

.p13-top-snippet-button {
  min-width: 150px;
  margin-left: 20px;
  background-color: #000;
  line-height: 12px;
  text-align: center;
}

.p13-snippet-text {
  line-height: 16px;
}

.p13-top-snippet-block-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  margin-top: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e2e2e2;
}

.p13-mid-snippet-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e9e9e9;
}

.p13-mid-snippet-button {
  min-width: 150px;
  margin-left: 20px;
  background-color: #000;
  line-height: 12px;
  text-align: center;
}

.p13-mid-snippet-text {
  line-height: 16px;
}

.p13-top-snippet-text {
  color: #ffffff;
  line-height: 16px;
  letter-spacing: 1.25px;
}

.p13-head-menu-title {
  padding-bottom: 5px;
  padding-left: 5px;
  font-family: Questrial, sans-serif;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
}

.p13-left-wrapper-title {
  margin-top: 15px;
  margin-bottom: 0px;
  font-family: Questrial, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.p13-chat-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  padding: 15px 15px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e9e9e9;
}

.p13-chat-title {
  max-width: 400px;
  font-size: 16px;
}

.p13-whatsapp-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.p13-whatsapp-button {
  height: 38px;
  margin-left: 10px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #000;
}

.p13-midleft-block {
  margin-top: 15px;
}

.p13-underchat-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p13-statement-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: 170px;
  max-width: none;
  margin-right: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  background-color: #e9e9e9;
}

.p13-job-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  padding: 15px 20px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #e9e9e9;
}

.p13-statement-text {
  max-width: none;
  padding: 15px;
  font-family: Questrial, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.p13-job-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.p13-job-form-button {
  height: 38px;
  margin-left: 10px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #000;
}

.p13-job-title {
  max-width: 400px;
  font-size: 14px;
}

.p13-side-blog-image {
  width: auto;
  max-width: 100%;
  margin-bottom: 5px;
}

.p13-side-blog-title {
  width: auto;
  margin-top: 2.5px;
  color: #333;
  font-size: 16px;
  text-align: left;
  text-decoration: none;
}

.p13-side-blog-description {
  padding-top: 5px;
  color: rgba(51, 51, 51, 0.7);
  line-height: 18px;
  text-align: left;
}

.p13-side-blog-link {
  display: block;
  margin-top: 5px;
  padding-top: 0px;
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  color: #333;
  text-decoration: none;
}

.p13-side-blog-link:hover {
  font-size: 16px;
  text-decoration: none;
}

.p13-confidence-wrapper {
  margin-top: 20px;
}

.p13-circle-grid {
  -ms-grid-columns: 0.25fr 0.25fr 0.25fr 0.25fr 0.25fr;
  grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p13-trust-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
  padding-top: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.p13-trust-image {
  width: 90px;
  max-width: none;
  margin-bottom: 10px;
}

.p13-trust-text {
  width: auto;
  color: #333;
  text-align: center;
  text-decoration: none;
}

.p13-trust-section-title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: Questrial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.p13-conf-lower-picture {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url(../assets/images/1Asset-323x.png);
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.p13-conf-lower-text-line-1 {
  padding-bottom: 2.5px;
  font-size: 18px;
}

.p13-conf-lower-text-line-2 {
  font-size: 14px;
}

.p13-conf-lower-button {
  position: relative;
  top: 14px;
  padding-right: 25px;
  padding-left: 25px;
  background-color: #000;
}

.p13-recently-viewed-grid {
  margin-top: 15px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p13-recently-viewed-image {
  width: auto;
  max-width: 100%;
  margin-bottom: 5px;
}

.p13-recently-viewed-title {
  width: auto;
  margin-top: 15px;
  color: #333;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
}

.p13-recently-viewed-block {
  width: 160px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 0px;
  padding-top: 5px;
  text-align: center;
}

.p13-recently-viewed-product-title {
  width: auto;
  color: #333;
  text-align: center;
  text-decoration: none;
}

.p13-recently-viewed-product-title:hover {
  text-decoration: underline;
}

.grid-3 {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p13-side-blog-grid {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p14-wrapper {
  max-width: 800px;
  margin-top: 5px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p14-blog-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p14-blog-body {
  margin-top: 0px;
  padding: 0px 0px 1px;
  background-color: #e9e9e9;
}

.p14-blog-banner-block {
  padding-left: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.p14-blog-title-block {
  width: 100%;
  min-width: auto;
  margin-top: -85px;
  margin-bottom: 10px;
  background-color: #fff;
}

.p14-blog-title {
  width: 100%;
  margin-top: 0px;
  padding-top: 50px;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #fff;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

.p14-blog-body-block {
  margin-left: 0px;
  background-color: #fff;
}

.p14-blog-body-paragraph {
  margin-top: 0px;
  padding: 20px 20px 30px;
}

.p14-blog-date {
  padding-right: 5px;
  text-align: right;
}

.p14-blog-author {
  text-align: right;
}

.p14-publisher-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.image-20 {
  width: auto;
  max-width: none;
}

.p14-recent-posts-wrapper {
  width: auto;
  max-width: none;
  margin-top: 31px;
  margin-left: 5px;
  padding-left: 15px;
  border: 1px none #000;
}

.p14-recent-posts-block-heading {
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
}

.p14-recent-posts-grid-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.p14-recent-post-grid {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p7-discount-price-span {
  padding-right: 10px;
  padding-left: 10px;
}

.p7-discount-total-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p11-discount-amount {
  font-size: 11px;
  line-height: 18px;
}

.p5-discount-price {
  padding-right: 10px;
  padding-left: 10px;
}

.p5-discount-total-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p5-discount-field {
  margin-right: 5px;
  margin-bottom: 0px;
}

.p5-discount-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.p5-discount-button {
  background-color: #000;
}

.p18-body-wrapper {
  height: auto;
}

.p18-reset-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 40px auto 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p18-reset-title {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 26px;
  text-align: center;
}

.p18-reset-instructions {
  max-width: 280px;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #8d8d8d;
}

.p18-reset-field {
  width: 250px;
  margin-bottom: 20px;
  padding-left: 5px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #000;
}

.p18-reset-button {
  width: 250px;
  margin-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #000;
}

.p18-help-text {
  margin-top: 15px;
  font-size: 12px;
}

.link-2 {
  color: #4b4b4b;
}

.p18-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.p19-body-wrapper {
  height: auto;
}

.p19-form-title {
  margin-top: 15px;
  margin-bottom: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 26px;
  text-align: center;
}

.p19-new-password-field-1 {
  width: 250px;
  margin-bottom: 20px;
  padding-left: 5px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #000;
}

.p19-new-password-field-2 {
  width: 250px;
  margin-bottom: 20px;
  padding-left: 5px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #000;
}

.p19-save-password-button {
  width: 250px;
  margin-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #000;
}

.p19-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.p19-reset-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 40px auto 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p19-help-text {
  margin-top: 15px;
  font-size: 12px;
}

.p20-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9998;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  max-width: none;
  padding-top: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  background-color: hsla(0, 0%, 100%, 0.8);
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.p20-modal-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 550px;
  height: auto;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0px;
  background-color: #fff;
}

.p20-form-block {
  margin-top: 10px;
}

.p20-form-content {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
  z-index: 0;
  overflow: visible;
  margin-top: 15px;
  padding: 16px 32px 0px;
  background-color: #fff;
}

.p20-form-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #000;
  font-family: Questrial, sans-serif;
}

.p20-form-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-align: left;
}

.p20-verify-button {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #000;
  text-align: center;
}

.p20-verification-field {
  margin-top: 20px;
}

.p20-text-message {
  margin-top: 20px;
  font-family: Questrial, sans-serif;
}

.p20-help-text {
  margin-top: 0px;
  font-size: 12px;
}

.link-3 {
  color: #505050;
}

.p21-main-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 0px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Questrial, sans-serif;
}

.p21-category-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p21-left-wrapper {
  margin-right: 25px;
}

.p21---side-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.p21---side-bar.seperator {
  max-width: 280px;
  min-width: 220px;
  padding-right: 8px;
  border-right: 1px solid #e9e9e9;
}

.p21---sidebar-block {
  margin-bottom: 32px;
}

.p21-cat-title {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-right: 20px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-align: right;
}

.p21-left-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: auto;
  padding-right: 16px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.p21-right-wrapper {
  max-width: 700px;
  min-width: 700px;
  margin-right: 200px;
}

.p21-inner-content-block {
  margin-right: 10px;
  margin-left: 10px;
}

.p21-field-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  min-width: 180px;
  padding-right: 20px;
  padding-left: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.p21-save-button {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #000;
}

.p21-first-name-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #000;
}

.p21-second-name-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #000;
}

.p21-right-wrapper-title {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-right: 20px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-align: left;
}

.p21-menu-item {
  margin-top: 0px;
  margin-bottom: 5px;
  padding-right: 20px;
  border-bottom: 1px none #000;
  color: rgba(51, 51, 51, 0.6);
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  text-align: right;
}

.p21-menu-item-selected {
  margin-top: 0px;
  margin-bottom: 5px;
  padding-right: 20px;
  font-family: Questrial, sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  text-align: right;
}

.p21-link {
  color: rgba(51, 51, 51, 0.6);
  text-decoration: none;
}

.p21-link:hover {
  color: #333;
}

.p21-first-name-field {
  min-width: 450px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px none #000;
}

.p21-last-name-field {
  min-width: 450px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px none #000;
}

.p21-email-field {
  min-width: 450px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px none #000;
}

.p21-phone-field {
  min-width: 450px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px none #000;
}

.p21-company-name-field {
  min-width: 450px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px none #000;
}

.p21-company-reg-field {
  min-width: 450px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px none #000;
}

.p21-vat-field {
  min-width: 450px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px none #000;
}

.p21-rest-password-link {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
}

.p21-account-no-field {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
}

.p22-inner-content-block {
  margin-right: 10px;
  margin-left: 10px;
}

.p22-filter-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.p22-table-title-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
  border-top: 1px solid #000;
}

.p22-order-column-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid solid;
  border-width: 1px;
  border-color: #000;
}

.p22-column-title-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 20px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p22-column-title {
  max-width: none;
  min-width: 150px;
  padding-right: 20px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 14px;
}

.p22-order-title {
  max-width: 200px;
  min-width: 150px;
  padding-right: 20px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 14px;
}

.p22-order-title-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 20px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p22-order-total-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px none #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p22-order-date-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p22-order-date-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 20px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p22-order-date {
  max-width: 60px;
  min-width: 180px;
  padding-right: 10px;
  padding-left: 7px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-unit-price-title-copy {
  max-width: 62px;
  min-width: 62px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}

.p11-unit-price-column-copy {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p22-link-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p22-link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  min-height: 20px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #000;
}

.p22-link-title {
  max-width: 62px;
  min-width: 150px;
  padding-right: 0px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 14px;
}

.p22-row-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
}

.p22-order-ref {
  max-width: 150px;
  min-width: 150px;
  padding-right: 20px;
  padding-left: 5px;
  font-size: 12px;
}

.text-block-10 {
  max-width: 200px;
  min-width: 150px;
  padding-right: 20px;
  padding-left: 10px;
  font-size: 12px;
}

.p22-order-total {
  max-width: 200px;
  min-width: 150px;
  padding-right: 20px;
  padding-left: 10px;
  font-size: 12px;
}

.p22-order-date-title {
  max-width: 60px;
  min-width: 180px;
  padding-right: 10px;
  padding-left: 7px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 14px;
}

.button {
  min-width: 130px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.p22-invoice-button {
  min-width: 130px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #000;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
}

.p21-link-selected {
  color: #333;
  text-decoration: none;
}

.p21-link-selected:hover {
  color: #333;
}

.p22-filter-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p22-from-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-field-2 {
  max-width: 130px;
  margin-right: 20px;
  margin-bottom: 0px;
  line-height: 20px;
}

.p22-from-date {
  max-width: 160px;
  margin-right: 10px;
  margin-bottom: 0px;
  line-height: 20px;
}

.p22-to-label {
  max-width: 130px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.p22-date-filter-button {
  background-color: #000;
}

.p22-order-filter-block {
  margin-left: 20px;
}

.p22-order-filter-sub-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p22-order-search-button {
  background-color: #000;
}

.p22-order-no-field {
  max-width: 160px;
  margin-right: 10px;
  margin-bottom: 0px;
  line-height: 20px;
}

.p22-right-wrapper {
  max-width: 700px;
  min-width: 700px;
  margin-right: 200px;
  margin-bottom: 100px;
}

.p21-account-nav-mobile {
  display: none;
  max-width: 300px;
  min-width: 300px;
  margin-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.dropdown-toggle-4 {
  display: none;
  max-width: 300px;
  min-width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p22-account-nav-mobile {
  display: none;
  max-width: 300px;
  min-width: 300px;
  margin-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p22-dropdown-toggle {
  display: none;
  max-width: 300px;
  min-width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p23-right-wrapper {
  max-width: 700px;
  min-width: 700px;
  margin-right: 200px;
  margin-bottom: 50px;
}

.p23-title-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p23-title-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none none none solid;
  border-width: 1px;
  border-color: #000;
}

.p23-address-block-title {
  padding-right: 10px;
  padding-left: 10px;
}

.p23-row-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
}

.p23-title-column-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid solid;
  border-width: 1px;
  border-color: #000;
}

.p23-inner-grid-labels {
  max-width: 150px;
  min-width: 150px;
  padding-right: 20px;
  padding-left: 5px;
  font-size: 12px;
  line-height: 16px;
}

.p23-address-field-column-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.p23-address-save-button {
  min-width: 130px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 0px;
  background-color: #000;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
}

.p23-bill---address-line-1-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-bill---address-line-2-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-bill---town-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-bill---county-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-bill---postal-code-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-bill---country-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-billing-address-wrapper {
  margin-bottom: 30px;
}

.p23-shipping-address-title {
  padding-right: 10px;
  padding-left: 10px;
}

.p23-shipping-address-wrapper {
  margin-bottom: 30px;
}

.p23-ship---address-line-1-field-copy {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-ship---address-line-2-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-ship---town-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-ship---postal-code-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-ship---country-field {
  min-width: 300px;
  margin-bottom: 0px;
  border: 1px none #000;
  line-height: 16px;
}

.p23-ship---county-field {
  margin-bottom: 0px;
  border: 1px none #000;
}

.p24-form {
  margin-left: 10px;
}
.p24-support-submit-button {
  padding-right: 25px;
  padding-left: 25px;
  background-color: #000;
}
.p24-query-description-field {
  min-height: 120px;
}
.p24-attachment-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
}
.p24-attached-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.re-p24-attached-file-link {
  margin-left: 10px;
  color: #333;
}
.p24-delete-attachment-button {
  display: block;
  overflow: visible;
  max-height: 15px;
  max-width: 20px;
  min-height: 15px;
  min-width: 20px;
  margin-left: 10px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: #000;
  font-size: 10px;
  line-height: 0px;
  text-align: center;
}
.p24-modal-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  height: auto;
  margin: auto;
  padding-right: 25px;
  padding-bottom: 20px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0px;
  background-color: #fff;
}
.p24-upload-modal-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
//display: none;
  width: 100%;
  height: 100%;
  max-width: none;
  padding-top: 200px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.8);
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.p24-modal-title {
  margin-top: 15px;
  font-family: Questrial, sans-serif;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
}
.p24-progress-bar-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
  border: 1px solid #000;
}
.p24-progress-bar-tracker {
  margin: 4px;
  padding-right: 0px;
  padding-left: 0px;
}
.p24-progress-percentage {
  text-align: left;
}
.p24-progress-details-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.p24-total-progress-size {
  margin-left: 5px;
}
.p24-progress-size-uploaded {
  margin-left: 10px;
}
.p25-form {
  margin-left: 10px;
}
.p25-artwork-form-field-title {
  font-weight: 400;
}
.p25-artwork-notes {
  min-height: 80px;
}
.p25-attachment-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
}
.re-p25-attached-file-link {
  margin-left: 10px;
  color: #333;
}
.p25-upload-artwork-files {
  height: 30px;
  padding: 0px 25px;
  background-color: #000;
  margin-bottom: 10px; /*this is mehaks alteration beacause I have added a file upload button*/
}
.p25-delete-selected-file {
  display: block;
  overflow: visible;
  max-height: 15px;
  max-width: 20px;
  min-height: 15px;
  min-width: 20px;
  margin-left: 10px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: #000;
  font-size: 10px;
  line-height: 0px;
  text-align: center;
}
.p25-historical-upload-title {
  margin-top: 40px;
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
  font-size: 18px;
}
.p25-table-title-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
  border-top: 1px solid #000;
}
.p25-ref-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid solid;
  border-width: 1px;
  border-color: #000;
}
.p25-column-title {
  max-width: 150px;
  min-width: 150px;
  padding-left: 5px;
}
.p25-ref {
  max-width: 150px;
  min-width: 150px;
  padding-right: 20px;
  padding-left: 10px;
  font-size: 12px;
}
.p25-ref-field-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid solid;
  border-width: 1px;
  border-color: #000;
}
.p25-file-name-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 310px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid solid none;
  border-width: 1px;
  border-color: #000;
}
.p25-row-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-top: 1px solid #000;
}
.p25-file-name-field-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px none #000;
}
.p25-file-name {
  max-width: 315px;
  min-width: 315px;
  padding-right: 20px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 12px;
}
.p25-upload-date-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid solid none;
  border-width: 1px;
  border-color: #000;
}
.p25-upload-date {
  max-width: 110px;
  min-width: 110px;
  padding-right: 10px;
  padding-left: 7px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: Questrial, sans-serif;
  font-size: 12px;
}
.p25-upload-title {
  max-width: 110px;
  min-width: 110px;
  padding-left: 5px;
}
.p25-upload-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid solid none;
  border-width: 1px;
  border-color: #000;
}
.p25-file-name-title {
  max-width: 320px;
  min-width: 320px;
  padding-left: 5px;
}
.p25-file-view-title {
  max-width: 120px;
  min-width: 120px;
  padding-left: 5px;
}
.p25-file-view-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid solid none;
  border-width: 1px;
  border-color: #000;
}
.p25-button-block {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none solid solid none;
  border-width: 1px;
  border-color: #000;
}
.p25-view-file-button {
  min-width: 100px;
  margin: 5px 10px;
  background-color: #000;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
}
.p25-upper-row-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.p25-lower-notes-row {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
}
.p25-upload-notes {
  padding: 5px 5px 5px 15px;
  font-size: 12px;
}
.p25-right-wrapper {
  max-width: 700px;
  min-width: 700px;
  margin-right: 200px;
  margin-bottom: 50px;
}
.p26-add-to-cart-button {
  min-width: 100px;
  margin: 5px 10px;
  background-color: #000;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
}
.p26-product-title {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: left;
}
.p26-product-sku {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: left;
}
.p26-price-quantity-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  margin-top: 5px;
  margin-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  max-width: 190px;
  min-width: 190px;
  margin-top: 7px;
  margin-right: 50px;
  margin-left: 10px;
}
.p26-product-image {
  height: 110px;
  padding-top: 0px;
  min-width: 96px; /*added by mehak to fix saved product css*/
}
.p26-dropdown-toggle {
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  text-decoration: none;
}
.p26-dropdown-text {
  padding-left: 10px;
  font-size: 12px;
  text-decoration: none;
}
.p26-dropdown-list {
  margin-top: 20px;
}
.p26-dropdown-list.w--open {
  margin-top: 20px;
}
.p26-watched-item-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #000;
  border-bottom: 1px none #000;
}
.p26-watched-item-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.p26-product-info-block {
  width: 100%;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.p26-button-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.p26-passive-button {
  min-width: 100px;
  margin: 2px 10px 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
}
.p26-remove-item-button {
  color: #000;
  font-size: 10px;
  text-align: center;
  text-decoration: none;
}
.p26-in-stock-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -2px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.p26-in-stock-text {
  margin-right: 0px;
  color: #67cca0;
  font-size: 12px;
}
.p26-in-stock-dot {
  width: 5px;
  height: 5px;
  margin-right: 5px;
  border-radius: 16px;
  background-color: #67cca0;
  font-size: 12px;
  line-height: 10px;
}
.p26-price-block {
  margin-top: -15px;
  padding-top: 0px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
}
.p26-item-price {
  font-size: 16px;
}
.p26-unit-price {
  margin-bottom: -3px;
  font-size: 12px;
}
.p26-view-product-button {
  min-width: auto;
  margin: 0px;
  padding: 0px;
  border: 1px none #000;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  text-decoration: underline;
}
.p26-additional-option-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  margin-top: 5px;
  margin-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
  text-align: left;
}
.p26-pagination-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.p26-pagination-last-page {
  margin-right: 5px;
  margin-left: 5px;
  color: #333;
  font-size: 16px;
  text-decoration: none;
}
.p26-pagination-next-page {
  margin-right: 5px;
  margin-left: 5px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}
.p26-pagination-current-page {
  margin-right: 3px;
  margin-left: 3px;
  padding-right: 7px;
  padding-left: 7px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.4);
}
.p26-pagination-pages-available {
  margin-right: 3px;
  margin-left: 3px;
  padding-right: 7px;
  padding-left: 7px;
  color: rgba(51, 51, 51, 0.4);
}
.p26-logged-in-quote-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  display: none;
  width: 100%;
  height: 100%;
  max-width: none;
  padding-top: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.8);
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.p26-modal-quote-title {
  margin-top: 15px;
  font-family: Questrial, sans-serif;
  font-size: 22px;
  line-height: 30px;
}
.p26-modal-notes {
  min-height: 100px;
  font-family: Questrial, sans-serif;
}
.p26-quantity-field {
  font-family: Questrial, sans-serif;
}
.p26-estimated-date {
  font-family: Questrial, sans-serif;
}
.p26-contact-time {
  font-family: Questrial, sans-serif;
}
.p26-quote-description {
  margin-top: 15px;
  font-family: Questrial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}
.p26-modal-product-name {
  font-family: Questrial, sans-serif;
}
.p1---save-to-watch-list-button {
  margin-top: 10px;
  margin-left: 15px;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #000;
  background-color: #fff;
  color: #333;
  line-height: 18px;
}
.p27-checkout-wrapper {
  max-width: 1280px;
}
.p27-left-checkout-block {
  margin-right: 20px;
  padding-top: 16px;
  padding-right: 32px;
  padding-left: 32px;
  background-color: #fff;
}
.p27-right-checkout-block {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
  z-index: 0;
  width: 250px;
  padding: 16px 15px;
  background-color: #fff;
}
.p27-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
}
.p27-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.p27-product-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 15px;
  padding-top: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.p27-option-block {
  margin-left: 10px;
  padding-left: 10px;
  border-top: 1px none #000;
  border-left: 1px none rgba(0, 0, 0, 0.4);
  min-width: 200px;
}
.p27-product-title {
  min-width: 250px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: left;
}
.p27-product-sku {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 12px;
  font-style: italic;
  text-align: left;
}
.p27-product-image {
  height: 50px;
  padding-top: 0px;
}
.p27-unit-price-block {
  margin-left: 10px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-left: 1px none rgba(0, 0, 0, 0.4);
}
.p27-additional-option-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  margin-top: 2.5px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
  text-align: left;
}

.p27-price-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 18px;
  margin-top: 0px;
  margin-bottom: 2.5px;
  margin-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}
.p27-product-info-block {
  width: 100%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.icon {
  position: static;
  margin-right: 0px;
}
.icon-2 {
  position: static;
  margin-right: 0px;
  margin-left: 10px;
}
.p27-line-total-block {
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 0px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}
.p27-line-total {
  margin-bottom: 0px;
  font-size: 16px;
}
.p27-stock-status {
  margin-right: 0px;
  margin-bottom: -3px;
  color: #67cca0;
  font-size: 12px;
}
.p27-dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-decoration: none;
}
.p27-dropdown-text {
  padding-left: 0px;
  font-size: 12px;
  text-decoration: none;
  border:none;
  background: none;
}
.p27-unit-price {
  margin-bottom: 0px;
  font-size: 16px;
}
.p27-remove-block {
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  min-width: 50px;
}
.p27-checkout-title {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}
.p27-selection-title {
  min-width: 170px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: left;
}
.p27-unit-price-title {
  margin-bottom: 0px;
  padding-right: 0px;
  font-size: 14px;
}
.p27-total-price-title {
  margin-bottom: 0px;
  padding-left: 0px;
  font-size: 14px;
}
.p27-title-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-top: 0px;
  border-top: 1px none rgba(0, 0, 0, 0.2);
}
.p27-line-total-title {
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 0px;
  padding-left: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-left: 1px none rgba(0, 0, 0, 0.3);
}
.p27-total-row-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-bottom: 5px;
  padding-top: 10px;
  border-top: 1px solid #000;
}
.p27-checkout-button {
  display: inline-block;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #000;
  text-align: center;
}
.p27-grand-total {
  padding-right: 10px;
  padding-left: 0px;
  font-size: 16px;
}
.p27-grand-total-price {
  padding-right: 10px;
  padding-left: 0px;
  font-size: 16px;
}
.p27-discount-field {
  margin-right: 5px;
  margin-bottom: 0px;
  font-size: 12px;
}
.p27-help-box {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.p27-current-step {
  font-size: 16px;
}
.p27-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 400;
}
.text-block-10 {
  margin-left: 10px;
}
.p27-next-step {
  margin-left: 10px;
  color: rgba(51, 51, 51, 0.4);
  font-size: 16px;
}
.p27-add-product {
  height: 30px;
  font-size: 12px;
  line-height: 16px;
}
.p27-card-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.p27-payment-icon {
  margin-right: 2.5px;
  margin-left: 2.5px;
  min-width: auto;
  max-width: 35px;
  max-height: 20px;
}
.p27-cart-notes {
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-style: italic;
}

.p5-additional-option-selected {
  padding-right: 10px;
  padding-left: 10px;
}

.p5-right-bar-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}
.p27-load-list-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-bottom: 1px none rgba(0, 0, 0, 0.3);
}
.p27-load-quote-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 25px;
  padding: 0px 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: #000;
  font-size: 12px;
  text-align: center;
}
.p27-load-list-field {
  width: 130px;
  height: 25px;
  margin-right: 5px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 12px;
}
.p27-cart-ref {
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-style: italic;
}
.p27-cart-snippet {
  position: static;
  left: 0%;
  top: 130px;
  right: 0%;
  bottom: 0%;
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-style: italic;
}
.p28-content-wrapper {
  max-width: 1280px;
  padding-right: 50px;
  padding-left: 50px;
}
.p28-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}
.p28-page-title-wrapper {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.p28-title-hash {
  font-size: 16px;
}
.p28-h1 {
  max-width: 700px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 45px;
  line-height: 50px;
}
.p28-h2 {
  max-width: 550px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 23px;
}
.p28-h3 {
  margin-top: 2px;
  margin-bottom: 5px;
  color: #22b573;
  font-size: 16px;
}
.p28-banner-image {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  margin-right: 0px;
  margin-left: 10px;
}
.p28-strip-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.p28-snippet-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 35px;
  margin-left: 35px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.p28-snippet-text {
  font-weight: 400;
}
.p28-bullet-point {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #000;
}
.bold-text-2 {
  margin-left: 5px;
  font-size: 16px;
}
.p28-content-wrapper-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 60px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.p28-c1-text-wrapper {
  margin-left: 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.p28-c1-text {
  max-width: 450px;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 25px;
}
.p28-accent-text {
  max-width: 450px;
}
.p28-h4 {
  margin-bottom: 5px;
  font-size: 20px;
}
.p28-content-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
}
.p28-c2-stock-wrapper {
  margin-right: 0px;
  padding-right: 40px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.p28-c2-block-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 0px;
}
.p28-c2-block-1-text-wrapper {
  margin-left: 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.p28-h4-style-2 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 20px;
  text-align: center;
}
.p28-c2-block-1-image {
  width: auto;
  height: auto;
  max-height: 100%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0% 0%;
  background-size: auto;
  background-attachment: scroll;
}
.p28-c1-image {
  width: auto;
  height: auto;
  max-height: 100%;
}
.p28-stock-block {
  margin-bottom: 20px;
}
.p28-h4-style-3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  text-align: center;
}
.p28-c2-wrapper {
  margin-left: 40px;
}
.p28-c2-block-2-image {
  width: auto;
  height: auto;
  max-height: 100%;
}
.p28-c2-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  margin-left: 0px;
}
.p28-content-wrapper-3 {
  display: none;
  margin-top: 0px;
  margin-bottom: 30px;
  padding-right: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.p28-c3-product-selection-wrapper {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.p28-c3-text-wrapper {
  margin-right: 50px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.p28-c3-text {
  max-width: 378px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 25px;
  text-align: right;
}
.p28-h4-c3 {
  margin-bottom: 5px;
  font-size: 30px;
  text-align: right;
}
.p28-accent-text-c3 {
  max-width: 450px;
  text-align: right;
}
.p28-dropdown-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}
.p28-dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  min-width: 378px;
  margin-bottom: 10px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e9e9e9;
}
.p29-dropdown-list {
  max-height: 100px;
}
.p29-dropdown-list.w--open {
  overflow: auto;
  max-height: 200px;
}
.p28-dropdown-title {
  margin-bottom: 5px;
}
.p28-price-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  border-bottom: 1px solid #000;
}
.p1---product-price-copy {
  font-family: Questrial, sans-serif;
  font-size: 24px;
  line-height: 30px;
  cursor: default;
}
.p1---product-price-copy.product-page {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 400;
}
.p1---product-price-copy.product-page.sale {
  margin-right: 10px;
  color: #616161;
  text-decoration: none;
}
.p28-product-price {
  font-family: Questrial, sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-style: italic;
  cursor: default;
}
.p28-product-price.product-page {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 400;
}
.p28-product-price.product-page.sale {
  margin-right: 10px;
  color: #616161;
  text-decoration: none;
}
.p28-unit-cost {
  margin-left: 10px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-family: Questrial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
  cursor: default;
}
.p28-unit-cost.product-page {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 0px;
  font-size: 16px;
  font-weight: 400;
}
.p28-unit-symbol {
  margin-left: 5px;
  font-family: Questrial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
  cursor: default;
}
.p28-unit-symbol.product-page {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
}
.p28-add-to-cart-button {
  padding-right: 50px;
  padding-left: 50px;
  background-color: #000;
}
.p28-c3-text-2 {
  max-width: 378px;
  margin-top: 20px;
  font-style: italic;
  text-align: right;
}
.p28-custom-quote-button {
  margin-top: 10px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1.5px none #000;
  background-color: #22b573;
  color: #fff;
  text-align: left;
}
.p28-c3-block-1-text-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.p28-content-wrapper-4 {
  margin-top: 60px;
  margin-bottom: 70px;
}
.p28-c4-title {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 26px;
}

.p28-call-out-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 95%, 0);
}

.p28-call-out-text {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 18px;
}

.image-21 {
  margin: 0px 0px 0px 30px;
}

.image-22 {
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 10px;
}

.p28---cart-sample-wrapper-copy {
  margin-top: 10px;
  margin-bottom: 10px;
}

.p28-free-sample-button {
  height: 40px;
  margin-left: 15px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}

.p28-call-to-confirm-price {
  margin-top: 1px;
  margin-left: 3px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 15px;
}

.p28-call-now-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p28-accent-text-c4 {
  max-width: 450px;
  margin-left: 0px;
  padding-right: 110px;
  padding-left: 0px;
  font-size: 12px;
  text-align: center;
}

.p28-accent-text-c5 {
  max-width: 450px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.link-9 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  color: #2b2b2b;
  font-size: 16px;
  text-decoration: none;
}

.p28-free-sample-button-2 {
  height: 40px;
  margin-top: 10px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}

.image-23 {
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 10px;
}

.p28-accent-text-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 40px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 13px;
  font-style: italic;
  letter-spacing: 1.5px;
}

.p28-accent-text-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px none rgba(0, 0, 0, 0.3);
  font-size: 13px;
  font-style: italic;
  letter-spacing: 1.5px;
}

.bold-text-8 {
  padding-left: 0px;
}

.p28-booking-link {
  color: #313030;
}

.p28-pouch-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  width: 100%;
  height: 100%;
  max-width: none;
  padding-top: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.8);
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.p28-pouch-modal-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  height: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0px;
  background-color: #fff;
  overflow-y: auto;
}

.p28-text {
  margin-top: 15px;
  margin-bottom: 5px;
  font-family: Questrial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}

.checkbox-field {
  text-align: left;
}

.p28-quote-button {
  height: 40px;
  margin-top: 5px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}

.link-10 {
  color: #333;
}

.link-11 {
  color: #333;
}

.link-12 {
  color: #333;
}

.p28-c4-process-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.p28-step-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.p28-c4-number {
  font-size: 60px;
  line-height: 60px;
}
.text-block-10 {
  max-width: 300px;
  margin-left: 10px;
}
.bold-text-3 {
  display: inline;
  margin-left: 0px;
  color: #22b573;
  font-size: 50px;
}
.p28-content-wrapper-5 {
  margin-top: 70px;
  margin-bottom: 70px;
}
.p28-c5-title {
  margin: 20px 10px 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  font-size: 26px;
}
.p28-step-text {
  max-width: 300px;
  margin-left: 10px;
}
.p28-spec-text {
  margin-top: 30px;
  margin-right: 10px;
  margin-left: 20px;
}
.p28-content-wrapper-6 {
  margin-bottom: 70px;
}
.p28-tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.p28-tab-link-tab4 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: hsla(0, 0%, 86.7%, 0.5);
  opacity: 1;
}
.p28-tab-link-tab3 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: hsla(0, 0%, 86.7%, 0.7);
  opacity: 1;
}
.p28-tab-link-tab-2 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.p28-tab-link-tab-2.w--current:active {
  background-color: #838383;
}
.p28-tab-link-tab-1.w--current {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.text-block-11 {
  text-align: center;
}
.p28-tab-menu-title {
  color: #000;
  text-align: center;
}
.p28-tab-link-tab-5 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: hsla(0, 0%, 86.7%, 0.3);
  opacity: 1;
}
.tab-pane-tab-3 {
  margin-top: 30px;
}
.tab-pane-tab-4 {
  margin-top: 30px;
}
.tab-pane-tab-5 {
  margin-top: 30px;
}
.p28-content-wrapper-7 {
  display: none;
}
.p28-c7-comparison-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.p28-c7-right-wrapper {
  margin-left: 40px;
}
.text-block-12 {
  max-width: 400px;
  text-align: right;
}
.p28-comparison-text {
  max-width: 400px;
  text-align: right;
}
.p28-comparison-text-right {
  max-width: 400px;
  text-align: left;
}
.p28-content-wrapper-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.p28-form {
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.p28-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.p28-form-para {
  min-height: 80px;
  margin-right: 0px;
  margin-left: 0px;
}
.p28-form-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}
.p28-form-field {
  width: 350px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.p28-submit-button {
  height: 37px;
  min-width: 150px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: #000;
  line-height: 16px;
}
.p28-c8-title {
  display: block;
  width: 730px;
  margin: 20px 10px 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
  font-size: 26px;
  text-align: center;
}
.p28-recommended-wrapper {
  display: none;
  margin-top: 40px;
  margin-bottom: 50px;
  padding-top: 29px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e9e9e9;
}
.p28-recommended-title {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 30px;
  font-family: Questrial, sans-serif;
  font-size: 30px;
  line-height: 42px;
  font-weight: 400;
}
.p28-recommended-picture {
  width: auto;
  height: auto;
  max-height: 400px;
}
.p28-recommended-product-text {
  max-width: none;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
  font-family: Questrial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #1d1d1d;
}
.p28-product-frame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.p28-cookie-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  display: none;
  width: 100%;
  height: 100%;
  max-width: none;
  padding-top: 120px;
  padding-bottom: 120px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.8);
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.p28-cookie-block {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  height: auto;
  margin: 342px auto 5px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0px;
  background-color: #fff;
  z-index:1; /*added by mehak to fix login form fields overlap issue*/
}
.p28-cookie-modal-close {
  padding-top: 0px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #c50f0f;
}
.p28-cookie-modal-title {
  margin-top: 10px;
  font-family: Questrial, sans-serif;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}
.p28-cookie-description {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: Questrial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.p28-cookie-accept-button {
  margin-bottom: 15px;
  background-color: #000;
  text-align: center;
}
.p28-cookie-link {
  font-size: 14px;
}

.p29-content-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p29-c2-wrapper {
  margin-left: 0px;
}

.p29-c2-block-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 60px;
  margin-left: 0px;
}

.p29-c2-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  margin-left: 0px;
}

.p29-c2-block-1-image {
  width: auto;
  height: auto;
  max-height: 100%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0% 0%;
  background-size: auto;
  background-attachment: scroll;
}

.p29-c2-block-1-text-wrapper {
  margin-left: 60px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p29-c2-block-2-image {
  width: auto;
  height: auto;
  max-height: 100%;
}

.p28-c2-block-1-text-wrapper-copy {
  margin-left: 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p29-content-wrapper-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p29-c1-image {
  width: auto;
  height: auto;
  max-height: 100%;
}

.p29-c1-text-wrapper {
  margin-left: 60px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p29-strip-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p29-snippet-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 50px;
  margin-left: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p29-recommended-wrapper-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 9px;
  margin-bottom: 50px;
  padding-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px none #e9e9e9;
}

.p28-c2-block-1-text-wrapper-copy-copy {
  margin-left: 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p28-c2-block-1-text-wrapper-copy-copy-copy {
  margin-left: 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p29-content-wrapper-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 70px;
  margin-bottom: 70px;
  padding-right: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p29-c3-title {
  margin-bottom: 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
  font-size: 40px;
  line-height: 40px;
}

.bold-text-4 {
  margin-left: 5px;
}

.p29-c3-subtitle {
  margin-top: 0px;
  font-size: 25px;
  font-style: normal;
}

.p29-c3-content-text {
  margin-top: 20px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}

.p29-c3-custom-print-button {
  margin-top: 25px;
  padding: 15px 30px;
  background-color: #000;
  font-size: 20px;
}

.bold-text-5 {
  font-size: 35px;
}

.bold-text-6 {
  font-size: 20px;
}

.p29-content-wrapper {
  margin-top: 80px;
  margin-bottom: 70px;
}

.p29-c4-title {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 26px;
  text-align: center;
}

.p29---recommended-products-block-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p29-mobile-link-wrapper {
  display: none;
}

.p29-mobile-links {
  margin-top: 25px;
  padding: 15px 30px;
  background-color: #000;
  font-size: 20px;
}

.p5-import-method-wrapper {
  padding-top: 10px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e9e9e9;
}

.p5-import-method-block {
  padding: 14px 24px;
  border: 1px solid #e9e9e9;
}

.p5-import-price-span {
  margin-bottom: 0px;
  margin-left: 10px;
}

.p5-import-price-span-copy {
  margin-bottom: 0px;
  margin-left: 10px;
}

.p5-import-method-description {
  margin-left: 30px;
  font-weight: 400;
}

.p5-import-field-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p5-import-vat-no {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-import-vat-no:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-import-vat-no::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-import-vat-no:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-import-vat-no::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-import-vat-no::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-import-vat-no.version-2 {
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-import-licence-no {
  height: 48px;
  margin-bottom: 32px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #1d1d1d;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease;
  transition: border-color 300ms ease;
}

.p5-import-licence-no:focus {
  border-bottom: 1px solid #1d1d1d;
}

.p5-import-licence-no::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-import-licence-no:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-import-licence-no::-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-import-licence-no::placeholder {
  color: rgba(29, 29, 29, 0.5);
}

.p5-import-licence-no.version-2 {
  position: static;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.p5-import-vat-block {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p5-import-licence-block {
  margin-left: 28px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p5-import-total-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p5-import-total {
  padding-right: 10px;
  padding-left: 10px;
}

.p5-import-title {
  margin-top: 10px;
  padding: 8px 0px;
  border-top-style: none;
  border-top-color: #e6e6e6;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}

.p5-information-text {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  border-top: 0.5px none #e6e6e6;
}

.p5-account-creation-text {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 13px;
}

.p5-account-password {
  margin-right: 5px;
  margin-bottom: 0px;
}

.p5-password-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border: 1px none #000;
}

.p5-google-block {
  height: 40px;
  border: 1px solid #000;
}

.p5-login-email-field {
  height: 30px;
}

.p5-login-password-field {
  height: 30px;
}

.p5-sign-in-button {
  height: 30px;
  margin-left: 20px;
  padding: 5px 20px 0px;
  background-color: #000;
}

.p5-login-left-block {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p5-login-right-block {
  margin-left: 20px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p5-login-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 0.5px solid #e6e6e6;
}

.p5-notice-text {
  max-width: 550px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-top: 0.5px none #e6e6e6;
  background-color: #eee;
}

.link-4 {
  color: #9d9d9d;
  font-size: 12px;
}

.p5-payment-option-1 {
  margin-right: 30px;
  padding-right: 20px;
}

.p5-payment-option-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p5-terms-text {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 12px;
}

.link-7 {
  color: #333;
}

.link-8 {
  color: #505050;
  font-size: 12px;
}

.p31-content-wrapper-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  margin-bottom: 70px;
  padding-right: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p31-c3-text-wrapper {
  margin-right: 50px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p31-c3-block-1-text-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.p31-accent-text-c3 {
  max-width: 400px;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
  text-align: left;
}

.p31-c3-product-selection-wrapper {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p31-c3-text-2 {
  max-width: 378px;
  margin-top: 20px;
  font-style: italic;
  text-align: left;
}

.p31-right-wrapper {
  margin-left: 30px;
}

.p31-left-wrapper {
  height: 650px;
  margin-right: 30px;
}

.p31-custom-quote-button {
  margin-top: 10px;
  margin-left: 20px;
  padding: 8px 20px;
  border: 1.5px solid #000;
  background-color: #fff;
  color: #000;
  text-align: left;
}

.p31-add-to-cart-button {
  padding-right: 50px;
  padding-left: 50px;
  background-color: #000;
}

.text-span-3 {
  color: #22b573;
}

.p31-product-image {
  width: 550px;
  height: 650px;
}

.p31-ink-wrapper {
  margin-top: 20px;
}

.p31-ink-row-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: auto;
  max-height: none;
}

.p31-ink-row-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 46px;
  margin-top: 10px;
}

.p31-lemon-yellow {
  width: 60px;
  height: 35px;
  max-height: 46px;
  margin-right: 7px;
  padding-right: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #fffc29;
}

.p31-bright-green {
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #18df30;
}

.p31-cerise {
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #f01e83;
}

.p31-orange {
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #f59227;
}

.p31-deep-red {
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #f10d01;
}

.p31-green {
  display: none;
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #0a7929;
}

.p31-azure-blue {
  display: none;
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #206cbe;
}

.p31-blue {
  display: none;
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #293bc0;
}

.p31-violet {
  display: none;
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #39096d;
}

.p31-black {
  display: none;
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #070707;
}

.p31-white {
  width: 60px;
  height: 35px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
}

.p31-ink-title {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
}

.p31-custom-mailer-pricing-title {
  max-width: none;
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 30px;
  line-height: 35px;
}

.p31-c2-block-1-image {
  width: auto;
  height: auto;
  max-height: 100%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0% 0%;
  background-size: auto;
  background-attachment: scroll;
}

.p31-c2-block-2-image {
  width: auto;
  height: auto;
  max-height: 100%;
}

.p31-h1 {
  max-width: none;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 50px;
}

.p31-c4-title {
  margin-top: 20px;
  margin-bottom: 25px;
  margin-left: 10px;
  font-size: 26px;
  text-align: left;
}

.p31-content-wrapper-8-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p31-green-row-2 {
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #0a7929;
}

.p31-azure-blue-row-2 {
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #206cbe;
}

.p31-blue-row-2 {
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #293bc0;
}

.p31-violet-row-2 {
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #39096d;
}

.p31-black-row-2 {
  width: 60px;
  height: 35px;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px;
  border-color: #e7e7e7;
  background-color: #070707;
}

.p1---cart-sample-wrapper {
  display: block;
  margin-bottom: 10px;
}

.p1---free-sample-button {
  height: 40px;
  margin-left: 15px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px none #000;
  background-color: #22b573;
  color: #fff;
}

.p1-request-sample-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  width: 100%;
  height: 90%;
  max-width: none;
  padding-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.8);
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.p1-sample-modal-block {
  display: block;
  width: 400px;
  height: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0px;
  background-color: #fff;
  overflow-y: auto;
}

.p1-sample-modal-title {
  margin-top: 20px;
  font-family: Questrial, sans-serif;
  font-size: 22px;
  line-height: 30px;
}

.p1-sample-modal-text {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: Questrial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}

.p1-sample-modal-button {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #22b573;
  text-align: center;
}

.p1-address-field {
  height: 90px;
}

.p1-sample-modal-requirements {
  height: 90px;
}

.checkbox-label-5 {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.p1-sample-modal-check-box {
  margin-top: 15px;
}

.p29-cta-button {
  height: 40px;
  margin-top: 0px;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px none #000;
  background-color: #22b573;
  color: #fff;
}

.p29-product-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 9px;
  margin-bottom: 50px;
  padding-top: 40px;
  padding-bottom: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px none #e9e9e9;
  background-color: hsla(0, 0%, 87.9%, 0.5);
}

.p29-product-frame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.p29-product-grid-image {
  width: auto;
  height: auto;
  max-width: 80%;
}

.p29-grid-title {
  margin-bottom: 0px;
  font-size: 20px;
}

.p29-stock-text {
  max-width: 300px;
  padding-top: 0px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  font-family: Questrial, sans-serif;
  color: #22b573;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.p29-grid-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  margin-top: 5px;
  margin-left: 0px;
  padding: 0px 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #000;
  background-color: rgba(65, 65, 65, 0);
  color: #000;
  font-size: 12px;
}

.p29-cta-button-2 {
  height: 40px;
  margin-top: 10px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}

.p29-grid-accent-text {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 400;
}

.p29-actual-grid {
  margin-bottom: 50px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.p28-custom-quote-button-2 {
  margin-top: 10px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1.5px none #000;
  background-color: #22b573;
  color: #fff;
  text-align: left;
}

.p28-dropdown-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.p28-column-text {
  margin-right: 0px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p28-content-wrapper-3-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 30px;
  padding-right: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p28-column-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p28-column-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p28-column-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p28-price-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}


.l1-p1-snippet-text {
  font-weight: 400;
}

.lp-p1-product-frame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.l1-p1-page-title-wrapper {
  margin-right: 20px;
  margin-left: 20px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.l1-p1-bullet-point {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #000;
}

.lp-p1-h3 {
  margin-top: 2px;
  margin-bottom: 5px;
  color: #22b573;
  font-size: 16px;
}

.l1-p1-pouchshop-banner {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  margin-right: 0px;
  margin-left: 10px;
}

.lp-p1-product-price {
  max-width: none;
  padding: 1px 5px 5px 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  font-family: Questrial, sans-serif;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
}

.lp-p1-product-price:hover {
  border-style: none;
}

.l1-p1-first-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.l1-p1-strip-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.lp-p1-text {
  margin: 20px;
  font-family: Questrial, sans-serif;
}

.l1-p1-h2 {
  max-width: 550px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 23px;
}

.lp-p1-pouchshop-grid {
  grid-auto-flow: row;
  grid-column-gap: 0px;
  -ms-grid-columns: minmax(180px, 3fr) minmax(180px, 3fr) minmax(180px, 3fr) minmax(180px, 3fr);
  grid-template-columns: minmax(180px, 3fr) minmax(180px, 3fr) minmax(180px, 3fr) minmax(180px, 3fr);
}

.lp-p1-cta {
  height: 40px;
  margin-top: 0px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px none #000;
  background-color: #22b573;
  color: #fff;
}

.lp-p1-page-wrapper2 {
  margin-top: 40px;
}

.lp-p1-title {
  margin-right: 20px;
  margin-left: 20px;
  font-family: Questrial, sans-serif;
  font-size: 30px;
}

.l1-p1-snippet-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 35px;
  margin-left: 35px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lp-p1-product-title {
  max-width: 210px;
  margin-top: 3px;
  margin-bottom: 2px;
  padding: 7px 5px 7px 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-top: 1px none rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-family: Questrial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.lp-p1-product-title:hover {
  text-decoration: underline;
}

.lp-p1-picutre {
  width: auto;
  height: auto;
}

.lp-p1-page-wrapper {
  max-width: 1200px;
  margin: 20px auto 40px;
  padding-bottom: 40px;
}

.lp-p--title-hash {
  font-size: 16px;
}

.lp-p-h1 {
  max-width: none;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 45px;
  line-height: 50px;
}

.p8-2-list-block {
  display: block;
  margin-top: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.p8-2-title-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-right: 10px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px none #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

.p8-2-title-row:hover {
  background-color: hsla(0, 0%, 91.4%, 0.5);
}

.p8-2-product-title-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  min-width: 250px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-left: 1px solid #e9e9e9;
}

.p8-2---dropdown-list-3-copy {
  overflow: auto;
  max-height: 200px;
}

.p8-2-volume-dropdown {
  margin-right: 10px;
}

.p8-2-product-title-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-right: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.p8-2-volume-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.p8-2-dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  min-width: 250px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none solid;
  border-width: 1px;
  border-color: #e9e9e9;
}

.p8-2-add-to-cart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  margin-left: 0px;
  padding: 0px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  font-size: 13px;
}

.p8-2-sku {
  display: inline;
  min-width: 70px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
}

.p8-2-price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  padding-right: 10px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-right: 1px solid #e9e9e9;
  border-left: 1px none #e9e9e9;
}

.p8-2-in-stock-dot {
  width: 8px;
  height: 8px;
  margin-right: 0px;
  border-radius: 16px;
  background-color: #67cca0;
}

.p8-2-sku-title {
  display: inline;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
}

.p8-2-product-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-left: 1px solid #e9e9e9;
}

.bold-text-9 {
  min-width: 250px;
}

.bold-text-10 {
  font-size: 12px;
}

.bold-text-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  min-width: 238px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #e9e9e9;
}

.p8-2-heading-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-right: 10px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px none #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

.p8-2-heading-row:hover {
  background-color: hsla(0, 0%, 100%, 0.5);
}

.p8-2-price-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  min-width: 80px;
  padding-right: 10px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-right: 1px solid #e9e9e9;
  border-left: 1px none #e9e9e9;
}

.slider {
  width: 700px;
  height: 700px;
}

.p31-enquiry-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: none;
  border-width: 1px;
  border-color: #000;
  border-radius: 0px;
  background-color: #fff;
}

.p31-enquiry-title {
  margin-top: 15px;
  font-family: Questrial, sans-serif;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
}

.p31---phone-no {
  font-family: Questrial, sans-serif;
  text-align: left;
}

.p31-quantity {
  text-align: left;
}

.p31-deadline {
  text-align: left;
}

.p31-quantity-required {
  text-align: left;
}

.p31-description {
  min-height: 100px;
  font-family: Questrial, sans-serif;
  text-align: left;
}

.p31-button {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #000;
}

.slide-nav {
  padding-right: 10px;
  padding-left: 10px;
}

.lp-p2-head-wrapper-copy {
  margin-top: 70px;
}

.lp-p2h1-copy {
  max-width: 550px;
  min-width: 550px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 45px;
  line-height: 50px;
}

.l1-p2-page-title-wrapper-copy {
  margin-right: 0px;
  margin-left: 20px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.lp-p2-text-copy {
  margin: 20px;
  font-family: Questrial, sans-serif;
}

.l1-p2-first-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p32-thankyou-wrapper {
  margin-top: 40px;
  text-align: center;
}

.p32-thankyou-para {
  text-align: center;
}

.p32-return-button {
  margin-top: 10px;
  background-color: #000;
}

.p33-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-top: 20px;
  padding: 60px 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #6866da;
  background-image: url('../assets/images/1Asset-31280x600.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: repeat-x;
}

.p33-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: #fff;
}

.p33-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p33-c1-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 140px;
  padding: 30px 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: rgba(34, 181, 115, 0.13);
}

.bold-text-12 {
  font-size: 30px;
  line-height: 30px;
}

.p33-c2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p33-c3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p33-c4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p33-c1-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid #22b573;
  color: #22b573;
  text-decoration: none;
}

.p33-c1-para {
  max-width: 1200px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 23px;
}

.p33-c2-para {
  max-width: 550px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 23px;
}

.p33-c4-card {
  max-width: 250px;
  margin-right: 10px;
  margin-left: 10px;
}

.p33-c4-wrapper {
  margin-right: 40px;
}

.p33-c4-card-text {
  max-width: 550px;
  margin-top: 5px;
  margin-bottom: 2px;
  color: rgba(51, 51, 51, 0.6);
  font-size: 16px;
  line-height: 23px;
}

.p33-c4-card-text-main {
  max-width: 550px;
  margin-top: 5px;
  margin-bottom: 2px;
  color: #333;
  font-size: 16px;
  line-height: 23px;
}

.p33-c4-card-left-para {
  max-width: 450px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 23px;
}

.p33-cta-1 {
  height: 40px;
  margin-top: 0px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px none #000;
  background-color: #e9e9e9;
  color: #000;
}

.p33-main-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 0px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-family: Questrial, sans-serif;
}

.p33-c4-h2 {
  max-width: 500px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 65px;
  line-height: 70px;
}

.text-span-4 {
  color: #22b573;
}

.p33-c3-right-wrapper {
  margin-left: 60px;
}

.p33-c3-title {
  max-width: 700px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 60px;
  line-height: 60px;
}

.p33-c2-right-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p33-c2-h2 {
  max-width: 550px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 50px;
  line-height: 50px;
}

.p33-c2-bulletpoint-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p33-c2-bulletpoint {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: #000;
}

.text-span-5 {
  color: #22b573;
}

.p33-c1-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 65px;
  height: 200px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p33-c1-h2 {
  max-width: 900px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 30px;
  line-height: 40px;
}

.p33-c1-rightcopy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: -24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p33-c1-side-text {
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  color: #22b573;
}

.p33-c5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p33-c5-h3 {
  max-width: 450px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 23px;
}

.text-span-6 {
  font-size: 45px;
}

.p33-c5-card {
  max-width: 225px;
  margin-right: 10px;
  margin-left: 10px;
}

.p33-c5-card-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.p33-c5-cta {
  height: 40px;
  margin-top: 10px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px none #000;
  background-color: #22b573;
  color: #fff;
}

.p33-c6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p33-c6-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 70px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p33-c6-left-sub-block {
  padding: 0px 20px;
  border-top: 15px none #22b573;
  border-bottom: 15px none #22b573;
  border-radius: 20px;
}

.p33-c6-bullet-block {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
}

.p33-c6-para {
  max-width: 450px;
  margin-top: 5px;
  margin-bottom: 2px;
  color: #333;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
}

.p33-c6-greenline {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  background-color: #22b573;
}

.text-span-7 {
  color: #22b573;
}

.p33-c6-cta {
  height: 40px;
  margin-top: 10px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  border: 1px none #000;
  background-color: #e9e9e9;
  color: #000;
}

.p33-c6-para2 {
  max-width: 1000px;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #22b573;
  font-size: 16px;
  line-height: 23px;
}

.p33-c7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 100px;
  margin-bottom: 60px;
  padding-top: 20px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #22b573;
  border-radius: 20px;
  opacity: 1;
}

.p33-c7-h3 {
  margin-top: 20px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 40px;
}

.p33-c7-form-field {
  width: 400px;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 16px;
  line-height: 30px;
}

.p33-c7-form-button {
  height: auto;
  min-width: 150px;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: #22b573;
  line-height: 16px;
  text-align: center;
}

.p33-c7-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 40px 40px 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.13);
}

.p33-c7-process {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: -24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p33-c7-h4 {
  max-width: 900px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
}

.p34-c1 {
  margin-top: 40px;
}

.p34-c1-h3 {
  max-width: 700px;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  font-size: 30px;
  line-height: 50px;
}

.p34-form-c1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 800px;
  margin-bottom: 40px;
  padding: 30px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.p34-form-c1-right {
  margin-left: 40px;
}

.p34-form-field {
  max-width: none;
  min-width: 300px;
}

.p34-form-split-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.p34-form-field-label {
  max-width: 640px;
}

.p34-form-dropdown {
  min-width: 300px;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p34-next-form {
  height: 40px;
  margin-top: 20px;
  margin-left: 0px;
  padding-right: 50px;
  padding-left: 50px;
  border: 1px none #000;
  background-color: #22b573;
  color: #fff;
}

.p34-form-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-top: 0px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px none #000;
  background-color: #22b573;
  color: #fff;
}

.p34-form-field-label-white {
  max-width: 640px;
  color: #fff;
}

.p34-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-top: 20px;
  padding: 60px 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #6866da;
  background-image: url('../assets/images/1Asset-31280x600.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: repeat-x;
}

.p21-side-bar-seller-menu {
  margin-top: 20px;
}

.p35-right-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  min-width: 900px;
  margin-right: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p35-listing-title-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border-bottom: 1px solid #000;
}

.p35-product-title2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 300px;
  margin-right: 40px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-title-stock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-title-sku {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  max-width: 150px;
  min-width: 150px;
  margin-right: 40px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.p35-listing-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.p35-product-views {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-views {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-product-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 300px;
  margin-right: 40px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-sku {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  max-width: 150px;
  min-width: 150px;
  margin-right: 40px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.p35-stock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-action-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-status-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-filter-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
}

.p35-sku-searcj {
  max-width: 160px;
  margin-right: 10px;
  margin-bottom: 0px;
  line-height: 20px;
}

.p35-product-title-search {
  max-width: 400px;
  min-width: 400px;
  margin-right: 10px;
  margin-bottom: 0px;
  line-height: 20px;
}

.p35-search-button {
  background-color: #000;
}

.p22-date-filter-button-copy {
  background-color: #000;
}

.p35-add-product {
  margin-right: 10px;
  margin-left: 20px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #22b573;
}

.p35-para {
  max-width: 800px;
  margin-bottom: 30px;
}

.p35-seller-mobile-notice {
  display: none;
}

.p35-title-sku-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  max-width: 150px;
  min-width: 150px;
  margin-right: 40px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.p36-title-order-no {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  max-width: 150px;
  min-width: 150px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.p36-order-no {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  max-width: 150px;
  min-width: 150px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.p36-title-order-total {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 120px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p36-order-total {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 120px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p36-title-order-date {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 120px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p36-order-date {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 120px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p36-title-order-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 140px;
  margin-right: 10px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p35-status-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p36-order-status-drop-down {
  display: inline-block;
  min-width: 140px;
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.p36-dropdown {
  margin-right: 0px;
  margin-left: 0px;
}

.p36-invoice-link {
  margin-top: 2px;
  margin-bottom: 2px;
}

.p36-action-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 80px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p36-title-customer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  min-width: 180px;
  margin-right: 10px;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p36-customer-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 170px;
  min-width: 170px;
  margin-right: 20px;
  margin-bottom: 0px;
  padding-right: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p36-invoice-link-copy {
  margin-top: 2px;
  margin-bottom: 2px;
}

.p36-order-search {
  max-width: 300px;
  min-width: 300px;
  margin-right: 10px;
  margin-bottom: 0px;
  line-height: 20px;
}

.p34-progress-wrapper {
  margin-bottom: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
}

.p34-progress-bar {
  width: 20%;
  height: 10px;
  background-color: #22b573;
  color: #22b573;
}

.p34-director-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p34-director-button-1 {
  height: 40px;
  margin-top: 0px;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 30px;
  padding-left: 30px;
  border: 1px none #000;
  background-color: #22b573;
  color: #fff;
  margin-top: 21px;
}

.p34-director-button-2 {
  height: 40px;
  margin-top: 0px;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px none #000;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.p34-document-row {
  margin-bottom: 20px;
}

.p34-file-link {
  max-width: 640px;
  margin-bottom: 0px;
}

.p34-id-upload {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-top: 0px;
  margin-left: 10px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  background-color: #000;
  color: #fff;
}

.p34-select-file-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p34-file-name {
  margin-left: 0px;
}

.p1-hide-price-message {
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #22b573;
  color: #22b573;
  font-size: 14px;
  text-align: center;
}

.p1-sold-by-field {
  display: block;
  margin-bottom: 10px;
  color: #333;
  text-decoration: none;
}

.text-span-8 {
  text-decoration: underline;
}

.p27-sold-by-field {
  display: block;
  margin-bottom: 0px;
  padding-left: 10px;
  color: #333;
  font-size: 12px;
  font-style: italic;
  text-decoration: none;
}

.p27-moq-error {
  margin-top: -5px;
  margin-bottom: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #d40004;
}

.p27-moq-error-message {
  color: #d40004;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .main-body {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p28-call-out-text {
    text-align: center;
  }

  .p28-accent-text-2 {
    text-align: center;
  }

  .p28-accent-text-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .footer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .footer-title {
    margin-bottom: 15px;
  }
  .search-input-field {
    min-width: 40vw;
  }
  .footer-categories-wrapper {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .footer-categories-wrapper.col {
    width: 100%;
    margin-top: 32px;
  }
  .copyright-section {
    margin-top: 3px;
  }
  .footer-link-2 {
    position: relative;
    margin-bottom: 0px;
  }
  .footer-row {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .footer-section {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-cat-accordian-content {
    overflow: hidden;
    margin-top: -13px;
    margin-bottom: 7px;
  }
  .footer-category-links {
    position: relative;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .side-menu-category-wrapper {
    width: 100%;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .burger-wrap {
    width: 21px;
  }
  .menu-button {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .nav-link-wrapper {
    margin-left: 0px;
  }
  .nav-logo-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .nav-cart-trigger {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .nav-cart-icon {
    display: block;
    width: 28px;
  }
  .nav-menu-2 {
    width: 40vw;
    padding-top: 40px;
    padding-right: 24px;
    padding-left: 24px;
  }
  .footer-title-newsletter {
    margin-bottom: 1px;
  }
  .search-wrapper {
    margin-left: 160px;
  }
  .nav-contact-wrapper {
    display: none;
  }
  .nav-phone-no {
    position: relative;
    margin-bottom: 0px;
  }
  .nav-timing-text {
    position: relative;
    margin-bottom: 0px;
  }
  .nav-wrapper {
    border: 1px none #000;
  }
  .top-menu-wrapper {
    margin-bottom: 5px;
  }
  .top-menu-text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 25px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .bread-crumb-container {
    display: block;
  }
  .bread-crumb-text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .bread-crumb-text-block {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .sidemenu-category-title {
    margin-bottom: 10px;
  }
  .side-menu-sub-category-title {
    position: relative;
    margin-bottom: 8px;
    padding-bottom: 2px;
  }
  .sidemenu-sub-category-accordian {
    overflow: hidden;
    margin-top: -13px;
    margin-bottom: 7px;
    text-align: left;
  }
  .side-menu-accordian-block {
    text-align: left;
  }
  .footer-category-wrapper {
    overflow: visible;
    width: 25%;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p1-product-section-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p1---tabs-menu {
    margin-left: 10px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .p1---product-details-block {
    margin-top: 15px;
    margin-left: 19px;
  }
  .p1---product-tick-icon {
    width: 16px;
    margin-top: 5px;
  }
  .p1---product-tick-text {
    font-size: 16px;
    line-height: 22px;
  }
  .p1---tab-link-block {
    margin-left: -16px;
    border-right-style: none;
  }
  .p1---product-info-tabs-3 {
    grid-column-gap: 16px;
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .p1---tab-titles {
    position: static;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .p1---recommended-prod-title {
    font-size: 30px;
    line-height: 36px;
  }
  .p1---recommended-products-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p2---faq-question {
    font-size: 20px;
    line-height: 26px;
  }
  .p2---faq-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .p2---faq-sidebar {
    position: static;
  }
  .p2---faq-title {
    font-size: 30px;
    line-height: 36px;
  }
  .text-field {
    border-radius: 0px;
  }
  .p3---contact-container {
    width: 95%;
  }
  .p3---contact-grid {
    grid-column-gap: 16px;
    grid-row-gap: 80px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .p3---contact-side-bar {
    position: static;
  }
  .p3---contact-side-bar.seperator {
    max-width: none;
    min-width: auto;
    margin-left: 0px;
    padding-right: 20px;
    border-right-style: solid;
  }
  .p3---form-block-2 {
    padding-top: 0px;
  }
  .p3---form-text-field-copy {
    border-radius: 0px;
  }
  .p4-page-title {
    font-size: 30px;
    line-height: 36px;
  }
  .p5-checkout-grid {
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    padding-left: 5px;
    grid-column-gap: 15px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .muted-text.small {
    font-size: 14px;
  }
  .checkout-content-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p5-exp-field {
    border-radius: 0px;
  }
  .p5-expiry-field {
    border-radius: 0px;
  }
  .p5-first-name-field {
    border-radius: 0px;
  }
  .p5-last-name-field {
    border-radius: 0px;
  }
  .p5-city-field {
    border-radius: 0px;
  }
  .p5-state-field {
    border-radius: 0px;
  }
  .p5-zip-field {
    border-radius: 0px;
  }
  .p5-address-line-1-field {
    border-radius: 0px;
  }
  .p5-address-line-2-field {
    border-radius: 0px;
  }
  .p5-company-field {
    border-radius: 0px;
  }
  .p5-cart-summary-block {
    display: none;
  }
  .p5---bread-crumb-text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .p5-customer-info-block {
    padding: 0px;
  }
  .p5-vat-button {
    margin-bottom: 10px;
  }
  .p5-address-block {
    padding: 0px;
  }
  .p5-recipient-name {
    border-radius: 0px;
  }
  .p5-recipient-mobile-no {
    border-radius: 0px;
  }
  .p5-customer-info-phone {
    border-radius: 0px;
  }
  .p5-customer-info-email {
    border-radius: 0px;
  }
  .p5-vat-field {
    border-radius: 0px;
  }
  .p6-login-signup-wrapper {
    margin-top: 19px;
  }
  .p6-login-block {
    left: 230px;
    margin-right: 10px;
  }
  .p6-login-email-field {
    border-radius: 0px;
  }
  .p6-login-password-field {
    border-radius: 0px;
  }
  .p6-signup-block {
    left: 230px;
    margin-left: 10px;
  }
  .p6-singup-first-name-field {
    border-radius: 0px;
  }
  .p6-signup-last-name-field {
    border-radius: 0px;
  }
  .p6-signup-email-field {
    border-radius: 0px;
  }
  .p6-signup-password-field {
    border-radius: 0px;
  }
  .p7-order-grid {
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    padding-left: 5px;
    grid-column-gap: 15px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .p7-customer-info-wrapper {
    padding: 0px;
  }
  .order-confirmation-alert {
    margin-right: 48px;
    margin-left: 48px;
  }
  .p7-order-confirmation-alert {
    margin-right: 48px;
    margin-left: 48px;
  }
  .p8-left-wrapper {
    margin-right: 13px;
    margin-left: 20px;
  }
  .grid {
    margin-left: -1px;
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-column-gap: 0px;
    grid-row-gap: 23px;
    -ms-grid-columns: minmax(230px, 3fr) minmax(230px, 3fr);
    grid-template-columns: minmax(230px, 3fr) minmax(230px, 3fr);
  }
  .side-menu-category-wrapper-copy {
    width: 100%;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p8-right-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .p8-left-menu {
    width: 100%;
    margin-bottom: 0px;
    padding-right: 20px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p8-cat-title {
    text-align: center;
  }
  .p8-side-menu-accordian-block {
    width: 100%;
    text-align: left;
  }
  .p8-sidemenu-category-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 19px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
    line-height: 22px;
    text-align: center;
  }
  .p8-side-menu-sub-category-title {
    position: relative;
    margin-bottom: 8px;
    padding-bottom: 2px;
    text-align: center;
  }
  .p8---side-bar-copy {
    position: static;
  }
  .p8---side-bar-copy.seperator {
    max-width: 200px;
    min-width: 210px;
    margin-left: 0px;
    padding-right: 0px;
    border-right-style: solid;
  }
  .p9-left-wrapper {
    margin-right: 13px;
    margin-left: 20px;
  }
  .p9---side-bar {
    position: static;
  }
  .p9---side-bar.seperator {
    max-width: 200px;
    min-width: 210px;
    margin-left: 0px;
    padding-right: 0px;
    border-right-style: solid;
  }
  .p9-cat-title {
    text-align: right;
  }
  .p9-left-menu {
    width: 100%;
    margin-bottom: 0px;
    padding-right: 20px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p8-product-grid {
    margin-left: -1px;
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-column-gap: 0px;
    grid-row-gap: 23px;
    -ms-grid-columns: minmax(230px, 3fr) minmax(230px, 3fr);
    grid-template-columns: minmax(230px, 3fr) minmax(230px, 3fr);
  }
  .p9-grid {
    margin-left: -1px;
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-column-gap: 0px;
    grid-row-gap: 23px;
    -ms-grid-columns: minmax(230px, 3fr) minmax(230px, 3fr);
    grid-template-columns: minmax(230px, 3fr) minmax(230px, 3fr);
  }
  .p9-left-contact-block {
    width: 100%;
    margin-bottom: 0px;
    padding-right: 20px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p9-featured-title {
    text-align: right;
  }
  .p10-close-text-span-copy {
    top: 15px;
  }
  .p12-right-wrapper {
    display: none;
  }
  .p12-content-wrapper {
    position: static;
  }
  .p12-form-block-2 {
    width: 90%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p12-form-field {
    width: 100%;
    margin-right: 0px;
  }
  .image-18 {
    min-width: 0%;
  }
  .grid-2 {
    grid-auto-flow: row;
    -ms-grid-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr) minmax(180px, 0.25fr);
    grid-template-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr) minmax(180px, 0.25fr);
  }
  .p13-wrapper {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .p13-header-menu {
    display: block;
  }
  .p13-banner-block {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .p13-top-snippet-block {
    background-color: #e9e9e9;
  }

  .image-19 {
    max-width: 100%;
  }
  .p12-grid-2-copy {
    grid-auto-flow: row;
    -ms-grid-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr) minmax(180px, 0.25fr);
    grid-template-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr) minmax(180px, 0.25fr);
  }
  .p13-grid {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-auto-flow: row;
    grid-column-gap: 9px;
    -ms-grid-columns: minmax(140px, 0.25fr) minmax(140px, 0.25fr) minmax(140px, 0.25fr) minmax(140px, 0.25fr) minmax(140px, 0.25fr);
    grid-template-columns: minmax(140px, 0.25fr) minmax(140px, 0.25fr) minmax(140px, 0.25fr) minmax(140px, 0.25fr) minmax(140px, 0.25fr);
  }
  .p13-product-block {
    width: 130px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .p13-split-wrapper {
    position: static;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p13-right-wrapper {
    display: block;
    max-width: none;
    margin-right: 10px;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-style: none;
    border-color: #000;
  }
  .p13-mid-snippet-text {
    font-size: 13px;
  }
  .p13-top-snippet-text {
    color: #000000;
    font-size: 13px;
    letter-spacing: 1px;
  }
  .p13-recently-viewed-grid {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
  }
  .p13-side-blog-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .p14-blog-title-block {
    width: 90%;
  }
  .p14-blog-body-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p14-blog-body-paragraph {
    width: 90%;
  }
  .image-20 {
    width: auto;
    max-width: 100%;
  }
  .p14-recent-posts-wrapper {
    display: block;
    max-width: none;
    margin-right: 10px;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-style: none;
    border-color: #000;
  }
  .p14-recent-post-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .p5-discount-block {
    margin-bottom: 10px;
    padding-bottom: 0px;
  }
  .p5-discount-button {
    margin-bottom: 10px;
  }
  .p18-body-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p19-body-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p21-main-body {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p21-category-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p21-left-wrapper {
    display: none;
    margin-right: 13px;
    margin-left: 20px;
  }
  .p21---side-bar {
    position: static;
  }
  .p21---side-bar.seperator {
    max-width: 200px;
    min-width: 260px;
    margin-left: 0px;
    padding-right: 0px;
    border-right-style: solid;
  }
  .p21---sidebar-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .p21-cat-title {
    text-align: center;
  }
  .p21-left-menu {
    width: 100%;
    margin-bottom: 0px;
    padding-right: 20px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p21-right-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 0px;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p21-field-title {
    min-width: 160px;
  }
  .p21-right-wrapper-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 10px;
    text-align: center;
  }
  .p21-menu-item {
    text-align: center;
  }
  .p21-menu-item-selected {
    text-align: center;
  }
  .p21-first-name-field {
    min-width: 200px;
  }
  .p21-last-name-field {
    min-width: 200px;
  }
  .p21-email-field {
    min-width: 200px;
  }
  .p21-phone-field {
    min-width: 200px;
  }
  .p21-company-name-field {
    min-width: 200px;
  }
  .p21-company-reg-field {
    min-width: 200px;
  }
  .p21-vat-field {
    min-width: 200px;
  }
  .p22-order-column-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p22-right-wrapper {
    min-width: 0px;
    margin-bottom: 50px;
    margin-left: 116px;
  }
  .p21-account-nav-mobile {
    display: inline-block;
  }
  .dropdown-toggle-4 {
    display: inline-block;
  }
  .p22-account-nav-mobile {
    display: inline-block;
  }
  .p22-dropdown-toggle {
    display: inline-block;
  }
  .p23-right-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 0px;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p23-title-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p23-title-column-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p24-form {
    min-width: 500px;
  }
  .p25-form {
    min-width: 500px;
    margin-left: 0px;
  }
  .p25-ref-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p25-ref-field-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p25-file-name-title-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p25-upload-title-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p25-file-view-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p25-right-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 0px;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p26-price-block {
    margin-left: 17px;
  }
  .p27-checkout-wrapper {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p27-right-checkout-block {
    margin-top: 10px;
    margin-right: 20px;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .p27-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p27-checkout-title {
    display: none;
  }
  .p27-cart-notes {
    margin-bottom: 20px;
  }
  .p27-load-list-block {
    margin-bottom: 10px;
    padding-bottom: 0px;
  }
  .p27-load-quote-button {
    margin-bottom: 10px;
  }
  .p27-cart-ref {
    margin-bottom: 20px;
  }
  .p27-cart-snippet {
    margin-bottom: 20px;
  }

  .p28-header-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .p28-page-title-wrapper {
    margin-top: 16px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .p28-picture-container {
    height: 100%;
    max-height: none;
  }

  .p28-h2 {
    max-width: none;
  }

  .p28-banner-image {
    height: 100%;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .p28-strip-wrapper {
    margin-top: 11px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-snippet-block {
    margin-left: 17px;
  }

  .p28-content-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .p28-content-wrapper-1 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p28-c2-stock-wrapper {
    display: none;
  }

  .p28-c2-block-1 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p28-c2-wrapper {
    margin-left: 0px;
  }

  .p28-c3-text-wrapper {
    margin-right: 30px;
  }

  .p28-dropdown-wrapper {
    margin-right: 10px;
  }

  .p28-c3-block-1-text-wrapper {
    margin-left: 10px;
  }

  .p28-spec-text {
    margin-left: 10px;
  }

  .p28-faq {
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .p28-form-block-2 {
    width: 90%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-form-field {
    width: 300px;
    margin-right: 0px;
  }

  .p28-recommended-title {
    font-size: 30px;
    line-height: 36px;
  }
  .p28-recommended-product-text {
    text-align: center;
  }

  .p29-c2-wrapper {
    margin-left: 0px;
  }

  .p29-c2-block-1 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p29-content-wrapper-1 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p29-strip-wrapper {
    margin-top: 11px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p29-snippet-block {
    margin-left: 17px;
  }

  .p29-recommended-wrapper-copy {
    display: none;
  }

  .p29---recommended-products-block-copy {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .p29-product-grid {
    display: block;
  }

  .p29-product-grid-image {
    max-width: 80%;
  }

  .p29-grid-title {
    text-align: center;
  }

  .p29-grid-accent-text {
    text-align: center;
  }
  .p5-import-vat-no {
    border-radius: 0px;
  }

  .p5-import-licence-no {
    border-radius: 0px;
  }

  .p5-password-block {
    margin-bottom: 10px;
    padding-bottom: 0px;
  }

  .p5-sign-in-button {
    margin-bottom: 10px;
  }

  .p5-payment-option-1 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .p31-content-wrapper-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p31-c3-text-wrapper {
    margin-right: 30px;
  }

  .p31-c3-block-1-text-wrapper {
    margin-left: 10px;
  }

  .p31-c3-product-selection-wrapper {
    margin-top: 15px;
  }

  .p31-right-wrapper {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .p31-left-wrapper {
    height: auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .p31-product-image {
    width: 350px;
    height: 450px;
  }

  .p31-green {
    display: none;
  }

  .p31-azure-blue {
    display: none;
  }

  .p31-blue {
    display: none;
  }

  .p31-violet {
    display: none;
  }

  .p31-black {
    display: none;
  }

  .p29-product-grid {
    display: block;
  }

  .p29-product-grid-image {
    max-width: 80%;
  }

  .p29-grid-title {
    text-align: center;
  }

  .p29-grid-accent-text {
    text-align: center;
  }

  .p28-dropdown-wrapper-2 {
    margin-right: 10px;
  }

  .l1-p1-page-title-wrapper {
    margin-top: 16px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .l1-p1-picture-container {
    height: 100%;
    max-height: none;
  }

  .l1-p1-pouchshop-banner {
    max-width: 100%;
    margin-left: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .l1-p1-first-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .l1-p1-strip-wrapper {
    margin-top: 11px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .l1-p1-h2 {
    max-width: none;
  }

  .lp-p1-pouchshop-grid {
    margin-left: -1px;
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-column-gap: 0px;
    grid-row-gap: 23px;
    -ms-grid-columns: minmax(230px, 3fr) minmax(230px, 3fr);
    grid-template-columns: minmax(230px, 3fr) minmax(230px, 3fr);
  }

  .l1-p1-snippet-block {
    margin-left: 17px;
  }
  .l1-p2-page-title-wrapper-copy {
    margin-top: 16px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .l1-p2-first-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .p33-title-wrapper {
    margin-top: 16px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .p33-c1-copy {
    margin-right: 10px;
    margin-left: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .p33-c2 {
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p33-c4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p33-c1-link {
    margin-top: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .p33-c1-para {
    max-width: none;
    font-size: 14px;
  }

  .p33-c2-para {
    max-width: none;
  }

  .p33-c4-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0px;
    margin-bottom: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c4-card-text {
    max-width: none;
  }

  .p33-c4-card-text-main {
    max-width: none;
  }

  .p33-c4-card-left-para {
    max-width: none;
    margin-right: 40px;
    margin-left: 40px;
    text-align: center;
  }

  .p33-cta-1 {
    margin-top: 10px;
  }

  .p33-main-body {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c4-h2 {
    text-align: center;
  }

  .p33-c3-right-wrapper {
    margin-top: 20px;
    margin-left: 20px;
  }

  .p33-c3-title {
    text-align: center;
  }

  .p33-c2-right-wrapper {
    margin-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c2-h2 {
    text-align: center;
  }

  .p33-c1-rightcopy {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c5 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p33-c5-h3 {
    max-width: none;
    margin-right: 40px;
    margin-left: 40px;
    text-align: center;
  }

  .p33-c5-left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c6 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p33-c6-right {
    margin-right: 20px;
    margin-left: 20px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .p33-c6-para {
    max-width: none;
  }

  .p33-c6-para2 {
    max-width: none;
    margin-right: 20px;
    margin-left: 20px;
    color: #000;
    text-align: center;
  }

  .p33-c7 {
    margin-right: 10px;
    margin-left: 10px;
    padding-top: 0px;
  }

  .p33-c7-h3 {
    margin-top: 40px;
    line-height: 40px;
    text-align: center;
  }

  .p33-c7-form-field {
    width: 300px;
    margin-right: 0px;
  }

  .p33-c7-a {
    padding-top: 0px;
  }

  .p33-c7-h4 {
    display: none;
  }

  .p34-c1-h3 {
    text-align: center;
  }

  .p35-right-wrapper {
    display: none;
    min-width: 0px;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p35-seller-mobile-notice {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 350px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p35-mobile-disclaimer {
    max-width: 300px;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding: 40px 20px;
  }
  .footer-links {
    width: 100%;
  }
  .footer-text {
    margin-bottom: 16px;
    text-align: center;
  }
  .footer-title {
    margin-bottom: 24px;
    text-align: center;
  }
  .copyright-text {
    text-align: center;
  }
  .copyright-block {
    margin-bottom: 24px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 22px;
    text-align: center;
  }
  .copyright-block.align-right {
    margin-bottom: 0px;
  }
  .footer-categories-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .copyright-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer-link-2 {
    margin-bottom: 32px;
    text-align: center;
  }
  .newsletter-wrapper {
    margin-bottom: 16px;
  }
  .footer-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .social-block {
    padding: 24px;
  }
  .footer-social-block {
    margin-top: 0px;
  }
  .footer-category-links {
    margin-bottom: 10px;
    text-align: center;
  }
  .side-menu-category-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .burger-wrap {
    margin-left: -12px;
  }
  .burger-icon {
    width: 20px;
  }
  .nav-logo-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .nav-cart-trigger {
    margin-left: 15px;
  }
  .nav-cart-icon {
    width: 28px;
  }
  .nav-menu-2 {
    width: 60vw;
  }
  .logo-2 {
    height: 32px;
  }
  .footer-title-newsletter {
    margin-bottom: 24px;
    text-align: center;
  }
  .nav-phone-no {
    margin-bottom: 32px;
    text-align: center;
  }
  .nav-timing-text {
    margin-bottom: 32px;
    text-align: center;
  }
  .top-menu-text-wrapper {
    display: none;
  }
  .top-menu-text-block {
    display: none;
  }
  .bread-crumb-container {
    display: none;
  }
  .bread-crumb-text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .bread-crumb-text-block {
    display: block;
  }
  .sidemenu-category-title {
    margin-bottom: 24px;
    text-align: center !important;
  }
  .side-menu-sub-category-title {
    margin-bottom: 10px;
    padding-bottom: 0px;
    text-align: left;
  }
  .footer-category-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p1-product-section-wrapper {
    padding-right: 2px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p1---tabs-menu {
    margin-left: 10px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .p1---tabs-content {
    margin-left: 5px;
  }
  .p1---product-details-block {
    margin-top: 15px;
    margin-left: 9px;
  }
  .p1---tab-link-block {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .p1---tab-link-block.w--current {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .p1---product-info-wrapper {
    padding-top: 15px;
  }
  .p1---tab-titles {
    margin-left: 15px;
    padding-bottom: 15px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 1px solid #e9e9e9;
  }
  .p1---recommended-products-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .image-8 {
    width: auto;
    height: auto;
  }
  .p2---faq-sidebar-title {
    font-size: 24px;
    line-height: 30px;
  }
  .sidebar-link {
    margin-bottom: 32px;
  }
  .p3---form-block-2 {
    padding-top: 0px;
  }
  .p5-checkout-grid {
    padding-right: 10px;
    padding-left: 10px;
    grid-auto-flow: row;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .p5---bread-crumb-text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .p6-login-signup-wrapper {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .p6-loginsignup-title {
    padding-right: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 23px;
  }
  .p6-title-block {
    padding-left: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .p6-form-containers {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .p6-login-block {
    left: 111px;
  }
  .p6-form-seperator {
    width: 280px;
    height: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-top-style: none;
    border-bottom-style: solid;
    border-left-style: none;
  }
  .p6-signup-block {
    left: 111px;
  }
  .p7-order-grid {
    padding-right: 10px;
    padding-left: 10px;
    grid-auto-flow: row;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .order-confirmation-alert {
    margin-right: 40px;
    margin-left: 40px;
  }
  .p7-order-confirmation-alert {
    margin-right: 40px;
    margin-left: 40px;
  }
  .p8-left-wrapper {
    margin-left: 10px;
  }
  .grid {
    -ms-grid-columns: minmax(160px, 3fr) minmax(160px, 3fr);
    grid-template-columns: minmax(160px, 3fr) minmax(160px, 3fr);
  }
  .side-menu-category-wrapper-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .p8-left-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .p8-sidemenu-category-title {
    margin-bottom: 24px;
    font-size: 15px;
    text-align: center;
  }
  .p8-side-menu-sub-category-title {
    margin-bottom: 10px;
    padding-bottom: 0px;
    text-align: center;
  }
  .p8---side-bar-copy.seperator {
    max-width: 180px;
    min-width: 180px;
  }
  .p9-left-wrapper {
    margin-left: 10px;
  }
  .p9---side-bar.seperator {
    max-width: 180px;
    min-width: 180px;
  }
  .p9-left-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .p8-product-grid {
    -ms-grid-columns: minmax(160px, 3fr) minmax(160px, 3fr);
    grid-template-columns: minmax(160px, 3fr) minmax(160px, 3fr);
  }
  .p9-grid {
    -ms-grid-columns: minmax(160px, 3fr) minmax(160px, 3fr);
    grid-template-columns: minmax(160px, 3fr) minmax(160px, 3fr);
  }
  .p9-left-contact-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .p9-featured-title {
    font-size: 30px;
    line-height: 35px;
    text-align: left;
  }
  .p10---modal-close-link {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .p10-checkout-title-copy {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .p10-close-text-span-copy {
    position: static;
  }
  .div-block-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p12-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p12-form-block-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .grid-2 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-grid-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr);
    grid-template-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr);
  }
  .p13-header-menu {
    display: none;
    min-height: auto;
    min-width: auto;
  }
  .p13-top-snippet-block {
    height: auto;
    min-height: 40px;
  }
  .image-19 {
    padding-left: 0px;
  }
  .p12-grid-2-copy {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-grid-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr);
    grid-template-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr);
  }
  .p13-grid {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-grid-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr) minmax(180px, 0.25fr);
    grid-template-columns: minmax(180px, 0.25fr) minmax(180px, 0.25fr) minmax(180px, 0.25fr);
  }
  .p13-product-block {
    width: auto;
  }
  .p13-top-product-image {
    width: 100%;
    max-width: none;
  }
  .p13-mid-snippet-block {
    height: auto;
    min-height: 45px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p13-mid-snippet-button {
    margin-bottom: 10px;
    margin-left: 0px;
  }
  .p13-mid-snippet-text {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .p13-top-snippet-text {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }
  .p13-statement-block {
    height: auto;
  }
  .p13-circle-grid {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    grid-auto-flow: row;
    -ms-grid-columns: 0.25fr 0.25fr 0.25fr;
    grid-template-columns: 0.25fr 0.25fr 0.25fr;
    text-align: center;
  }
  .p13-conf-lower-picture {
    padding-bottom: 35px;
  }
  .p13-conf-lower-text-line-1 {
    font-size: 16px;
  }
  .p13-conf-lower-text-line-2 {
    font-size: 12px;
  }
  .p13-conf-lower-button {
    top: 12px;
  }
  .p13-recently-viewed-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .p13-recently-viewed-block {
    width: auto;
  }
  .p20-form-title-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p20-form-title {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .p21-main-body {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p21-category-wrapper {
    margin-left: -4px;
  }
  .p21-left-wrapper {
    margin-left: 10px;
  }
  .p21---side-bar.seperator {
    max-width: 180px;
    min-width: 180px;
  }
  .p21-cat-title {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    text-align: right;
  }
  .p21-left-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .p21-right-wrapper {
    margin-right: 0px;
    margin-left: -9px;
  }
  .p21-inner-content-block {
    margin-left: 90px;
  }
  .p21-field-title {
    min-width: 120px;
    padding-right: 10px;
  }
  .p21-rest-password-link {
    min-width: 200px;
  }
  .p21-account-no-field {
    min-width: 200px;
  }
  .p22-filter-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p22-column-title {
    max-width: 120px;
    min-width: 120px;
  }
  .p22-order-title {
    max-width: 120px;
    min-width: 120px;
  }
  .p22-order-date {
    max-width: 120px;
    min-width: 120px;
  }
  .p22-link-title {
    max-width: 120px;
    min-width: 140px;
  }
  .p22-order-ref {
    max-width: 120px;
    min-width: 120px;
  }
  .p22-order-total {
    max-width: 120px;
    min-width: 120px;
  }
  .p22-order-date-title {
    max-width: 120px;
    min-width: 120px;
  }
  .p22-invoice-button {
    min-width: 120px;
  }
  .p22-order-filter-block {
    margin-left: 0px;
  }
  .p22-right-wrapper {
    margin-left: 9px;
  }
  .p21-account-nav-mobile {
    margin-left: 118px;
  }
  .p22-account-nav-mobile {
    margin-right: 0px;
    margin-left: 0px;
  }
  .p23-right-wrapper {
    margin-left: 50px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .p23-inner-grid-labels {
    max-width: 120px;
    min-width: 120px;
  }
  .p23-address-save-button {
    min-width: 120px;
  }
  .p24-form {
    min-width: 400px;
  }
  .p25-form {
    min-width: 400px;
  }
  .p25-table-title-wrapper {
    display: none;
  }
  .p25-ref {
    max-width: 120px;
    min-width: 120px;
  }
  .p25-row-wrapper {
    margin-top: 10px;
  }
  .p25-file-name-field-block {
    border-left-style: solid;
  }
  .p25-file-name {
    max-width: 120px;
    min-width: 120px;
  }
  .p25-upload-date-block {
    border-left-style: solid;
  }
  .p25-upload-date {
    max-width: 120px;
    min-width: 120px;
  }
  .p25-button-block {
    border-left-style: solid;
  }
  .p25-view-file-button {
    min-width: 120px;
  }
  .p25-upper-row-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p25-right-wrapper {
    margin-right: auto;
    margin-left: 100px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .p26-add-to-cart-button {
    min-width: 120px;
  }
  .p26-dropdown-toggle {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p26-watched-item-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p26-passive-button {
    min-width: 120px;
  }
  .p26-price-block {
    position: relative;
    margin-top: 12px;
    margin-left: 94px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-left-style: none;
    text-align: center;
  }
  .p26-view-product-button {
    min-width: 120px;
    text-align: center;
  }
  .p27-product-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p27-option-block {
    margin-left: 0px;
  }
  .p27-unit-price-block {
    margin-right: 10px;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .p27-additional-option-dropdown {
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .p27-price-dropdown {
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .p27-line-total-block {
    margin-left: 0px;
    padding-top: 5px;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    border-left-style: none;
  }
  .p27-line-total {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
  .p27-dropdown-toggle {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid #000;
    line-height: 25px;
  }
  .p27-dropdown-text {
    padding: 5px 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .p27-unit-price {
    margin-top: 3px;
    margin-left: 0px;
    padding-left: 9px;
  }
  .p27-remove-block {
    margin-right: 10px;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .p27-title-row {
    display: none;
  }
  .p27-load-list-block {
    margin-bottom: 0px;
  }
  .p27-load-quote-button {
    margin-bottom: 0px;
  }

  .p28-content-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .p28-content-wrapper-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-c1-text-wrapper {
    margin-top: 20px;
    margin-left: 0px;
  }

  .p28-c2-block-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-c2-block-1-text-wrapper {
    margin-top: 20px;
    margin-left: 0px;
  }

  .p28-c2-block-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-content-wrapper-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-c3-product-selection-wrapper {
    margin-top: 40px;
  }

  .p28-c3-text {
    text-align: center;
  }

  .p28-c3-block-1-text-wrapper {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p28-c4-process-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-step-block {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .p28-tabs-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-form-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-form-block-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-c8-title {
    width: auto;
  }

  .p28-recommended-picture {
    width: auto;
    height: auto;
  }

  .p28-recommended-product-text {
    font-size: 16px;
    line-height: 18px;
  }

  .p29-c2-block-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p29-c2-block-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p29-c2-block-1-text-wrapper {
    margin-top: 20px;
    margin-left: 0px;
  }

  .p28-c2-block-1-text-wrapper-copy {
    margin-top: 20px;
    margin-left: 0px;
  }

  .p29-content-wrapper-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p29-c1-text-wrapper {
    margin-top: 20px;
    margin-left: 0px;
  }

  .p28-recommended-product-text {
    font-size: 16px;
    line-height: 18px;
  }

  .p29-recommended-wrapper-copy {
    display: none;
  }

  .p28-c2-block-1-text-wrapper-copy-copy {
    margin-top: 20px;
    margin-left: 0px;
  }

  .p28-c2-block-1-text-wrapper-copy-copy-copy {
    margin-top: 20px;
    margin-left: 0px;
  }

  .p29-content-wrapper-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p29---recommended-products-block-copy {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .p29-mobile-link-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p29-mobile-links {
    width: 350px;
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
  }

  .p29-mobile-snippet {
    max-width: 350px;
    text-align: center;
  }

  .p31-content-wrapper-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p31-c3-block-1-text-wrapper {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p31-c3-product-selection-wrapper {
    margin-top: 40px;
  }

  .p31-ink-row-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .p31-green {
    display: none;
  }

  .p31-azure-blue {
    display: none;
  }

  .p31-blue {
    display: none;
  }

  .p31-violet {
    display: none;
  }

  .p31-black {
    display: none;
  }

  .p31-green-row-2 {
    display: block;
  }

  .p31-azure-blue-row-2 {
    display: block;
  }

  .p31-blue-row-2 {
    display: block;
  }

  .p31-violet-row-2 {
    display: block;
  }

  .p31-black-row-2 {
    display: block;
  }

  .p29-product-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .p29-product-grid-image {
    width: auto;
    height: auto;
  }

  .p29-stock-text {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  .p28-call-out-text {
    text-align: center;
  }

  .p28-accent-text-2 {
    text-align: center;
  }

  .p28-accent-text-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .p28-content-wrapper-3-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .lp-p1-pouchshop-grid {
    -ms-grid-columns: minmax(160px, 3fr) minmax(160px, 3fr);
    grid-template-columns: minmax(160px, 3fr) minmax(160px, 3fr);
  }

  .lp-p1-picutre {
    width: auto;
    height: auto;
  }

  .p33-header-wrapper {
    margin-top: 0px;
    margin-right: -36px;
    margin-left: -31px;
    padding: 20px 40px 40px;
  }

  .p33-title-wrapper {
    padding-left: 20px;
  }

  .p33-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }

  .p33-c1-copy {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .p33-c3-title {
    text-align: left;
  }

  .p33-c6-right {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: center;
  }

  .p34-form-c1 {
    min-width: 500px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .p34-form-c1-right {
    margin-left: 0px;
  }

  .p34-form-split-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .p34-header-wrapper {
    margin-top: 0px;
    margin-right: -36px;
    margin-left: -31px;
    padding: 20px 40px 40px;
  }

  .p35-right-wrapper {
    margin-right: 0px;
    margin-left: -9px;
  }

  .p35-filter-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 479px) {
  .container {
    margin-top: 13px;
  }
  .main-body {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .footer {
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
  }
  .footer-links {
    margin-top: 1px;
  }
  .footer-title {
    width: 100vw;
    margin-bottom: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid #e9e9e9;
  }
  .brand {
    padding-left: 0px;
  }
  .brand.footer {
    display: block;
    margin-bottom: 1px;
    padding-right: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .filled-btn.search-btn {
    margin-top: 15px;
  }
  .stripe-text {
    margin-right: 0px;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
  .search-input-field {
    height: 40px;
    min-width: 60vw;
    padding-right: 10px;
    text-align: center;
  }
  .copyright-text {
    margin-bottom: 8px;
    text-align: center;
  }
  .footer-categories-wrapper {
    margin-bottom: 15px;
  }
  .footer-categories-wrapper.col {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .copyright-section {
    margin-top: 2px;
    padding-right: 32px;
    padding-bottom: 24px;
    padding-left: 32px;
  }
  .succes-state {
    margin-bottom: 16px;
    border-color: #67cca0;
    color: #67cca0;
  }
  .footer-link-2 {
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    font-weight: 400;
  }
  .newsletter-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-section {
    padding-top: 25px;
    padding-right: 16px;
    padding-left: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .error-state {
    margin-bottom: 16px;
    color: #e84a5f;
  }
  .stripe-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer-cat-accordian-content {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 10px;
  }
  .footer-category-links {
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
  }
  .side-menu-category-wrapper {
    overflow: auto;
    width: 95vw;
    margin-right: 0px;
    margin-bottom: 1px;
    margin-left: 8px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .form-block {
    margin-bottom: 0px;
  }
  .burger-wrap {
    width: auto;
    margin-left: 0px;
  }
  .menu-button {
    margin-left: -13px;
    padding: 8px;
  }
  .back-icon {
    width: 16px;
  }
  .nav-logo-wrap {
    display: block;
  }
  .nav-cart-contact-wrapper {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin-top: -60px;
    margin-right: 25px;
    margin-left: 0px;
  }
  .nav-cart-trigger {
    margin-left: 215px;
    padding: 8px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .navbar {
    width: 95vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .justbulklogoheader {
    position: relative;
    margin-top: 0px;
    margin-bottom: 13px;
    margin-left: 1px;
    padding-bottom: 33px;
  }
  .nav-menu-2 {
    width: 100vw;
    padding-top: 56px;
    padding-right: 0px;
    padding-left: 0px;
    border-right-style: none;
  }
  .nav-link-2 {
    position: relative;
    top: -39px;
    margin-left: 17px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .nav-link-2.w--current {
    color: #1d1d1d;
  }
  .logo-2 {
    height: 28px;
  }
  .footer-title-newsletter {
    width: 100%;
    margin-bottom: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px none #e9e9e9;
  }
  .search-wrapper {
    width: 100%;
    margin-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .nav-search-field {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;

  }
  .search-block {
    width: 90%;
    margin-left: -28px;
  }
  .nav-phone-no {
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    font-weight: 400;
  }
  .nav-timing-text {
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    font-weight: 400;
  }
  .top-menu-container {
    display: none;
    margin-top: 13px;
  }
  .top-menu-text-wrapper {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin-top: -60px;
    margin-right: 25px;
    margin-left: 0px;
  }
  .nav-container {
    margin-top: 13px;
  }
  .bread-crumb-wrapper {
    height: 100%;
  }
  .bread-crumb-container {
    display: none;
    overflow: visible;
    height: auto;
    margin-top: 13px;
  }
  .bread-crumb-text-wrapper {
    position: relative;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin-top: 0px;
    margin-right: 25px;
    margin-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bread-crumb-text-block {
    display: block;
  }
  .sidemenu-category-title {
    width: 85vw;
    margin-bottom: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid #e9e9e9;
  }
  .side-menu-sub-category-title {
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-bottom: 0px;
    text-align: center;
  }
  .sidemenu-sub-category-accordian {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 10px;
    text-align: left;
  }
  .side-menu-accordian-block {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .footer-category-wrapper {
    overflow: visible;
    width: 95vw;
    margin-right: 0px;
    margin-bottom: 1px;
    margin-left: 8px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .p1-product-section-wrapper {
    display: block;
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p1-productpicwrapper {
    display: block;
  }
  .p1---tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 95vw;
    margin-top: 8px;
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .p1---tablink {
    padding-right: 5px;
  }
  .p1---tabs-content {
    width: auto;
    height: auto;
    max-width: 95vw;
    margin-left: 0px;
  }
  .p1---tabbed-picture-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .p1---product-details-block {
    max-width: 95vw;
    margin-top: 15px;
    margin-left: 0px;
  }
  .p1---product-tick-block {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .p1---brief-product-details {
    max-width: 95vw;
  }
  .p1---dropdown-toggle {
    min-width: 90vw;
  }
  .p1---add-to-cart {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .p1---tab-link-block {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .p1---tab-link-block.w--current {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .p1---product-info-wrapper {
    padding-top: 15px;
  }
  .additional-info-row {
    margin-bottom: 24px;
  }
  .additional-info-title {
    width: 100%;
  }
  .p1---tab-titles {
    padding-bottom: 15px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 1px solid #e9e9e9;
  }
  .p1---recommended-products-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p1---recommended-product-frame {
    margin-bottom: 20px;
    color: #333;
  }
  .image-9 {
    width: 100%;
    height: 100%;
  }
  .image-10 {
    width: 100%;
    height: 100%;
  }
  .image-11 {
    width: 100%;
    height: 100%;
  }
  .p1---modal-block {
    width: 300px;
  }
  .p2-faq-answer {
    font-size: 12px;
    line-height: 20px;
  }
  .p2---faq-question {
    font-size: 18px;
    line-height: 24px;
  }
  .p2---faq-title {
    font-size: 24px;
  }
  .text-field-spacer {
    display: none;
  }
  .sidebar-link {
    margin-right: 0px;
    margin-left: 0px;
  }
  .p3---form-block-2 {
    padding-top: 0px;
  }
  .p3---text-field-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p4-page-title {
    font-size: 24px;
    line-height: 30px;
  }
  .p5-left-checkout-block {
    padding-right: 15px;
    padding-left: 15px;
  }
  .muted-text.small {
    font-size: 12px;
  }
  .p5-checkout-title {
    font-size: 22px;
  }

  .p5-payment-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }

  .p5-last-name-block {
    margin-left: 0px;
  }
  .p5-name-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .p5-right-checkout-block {
    padding-right: 20px;
    padding-left: 20px;
  }

  .p5-order-item {
    margin-bottom: 19px;
  }
  .dropdown {
    margin-top: 4px;
    margin-left: -70px;
  }
  .p5-name-wrapper-copy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .p5---bread-crumb-text-wrapper {
    position: relative;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 25px;
    margin-top: 0px;
    margin-right: 25px;
    margin-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bread-crumb-wrapper-copy {
    height: 25px;
  }
  .p6-login-signup-wrapper {
    width: 95%;
  }
  .p6-loginsignup-title {
    font-size: 18px;
  }
  .p6-form-containers {
    margin-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .p6-login-block {
    left: 0px;
    width: 90%;
    margin-top: 10px;
    margin-right: 0px;
    margin-left: 0px;
    clear: none;
    -o-object-fit: contain;
    object-fit: contain;
  }
  .p6-login-heading {
    width: auto;
  }
  .p6-login-form-sub-block {
    width: auto;
  }
  .p6-login-button {
    width: auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .p6-signup-block {
    left: 0px;
    width: 90%;
    height: auto;
    margin-right: 0px;
    margin-left: 0px;
  }
  .p6-singup-title {
    width: auto;
  }
  .p6-signup-last-name-field {
    margin-left: 0px;
  }
  .p6-signup-button {
    width: auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .p6-signup-terms {
    width: auto;
  }
  .p6-signup-form-block {
    width: auto;
  }
  .p6-name-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .p7-left-checkout-block {
    padding-right: 15px;
    padding-left: 15px;
  }
  .p7-confirmation-title {
    font-size: 22px;
  }
  .p7-product-varient {
    margin-top: 17px;
    margin-left: -80px;
  }
  .p7-product-quantity-selected {
    margin-top: 3px;
    margin-left: -80px;
  }
  .p5-order-item-copy {
    margin-bottom: 19px;
  }
  .p7-order-item {
    margin-bottom: 19px;
  }
  .p7-checkout-title {
    font-size: 22px;
  }
  .p5-checkout-title-copy {
    font-size: 22px;
  }
  .p7-checkout-title-copy {
    font-size: 22px;
  }
  .order-confirmation-alert {
    margin-right: 24px;
    margin-left: 24px;
  }
  .p7-order-confirmation-alert {
    margin-right: 24px;
    margin-left: 24px;
  }
  .p7-product-price-span {
    margin-top: 9px;
  }
  .p5-product-price-span {
    margin-top: 10px;
  }
  .p5-summary-quantity {
    margin-top: 35px;
    margin-left: -70px;
    padding-left: 0px;
  }
  .p8-category-wrapper {
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p8-left-wrapper {
    margin-left: 50px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .p8-left-menu {
    overflow: auto;
    width: 95vw;
    margin-right: 0px;
    margin-bottom: 1px;
    margin-left: 8px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .p8-side-menu-accordian-block {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .p8-sidemenu-category-title {
    width: 85vw;
    margin-bottom: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid #e9e9e9;
  }
  .p8-side-menu-sub-category-title {
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-bottom: 0px;
    text-align: center;
  }
  .p8---side-bar-copy.seperator {
    max-width: none;
    min-width: auto;
    border-right-style: none;
  }
  .p8---product-frame {
    margin-right: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .p9-blog-wrapper {
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p9-left-wrapper {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .p9---side-bar.seperator {
    max-width: none;
    min-width: auto;
    border-right-style: none;
  }
  .p9-cat-title {
    text-align: left;
  }
  .p9-left-menu {
    overflow: auto;
    width: 95vw;
    margin-right: 0px;
    margin-bottom: 1px;
    margin-left: 8px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .p9---blog-menu-frame {
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
  .p8-product-grid {
    margin-right: 40px;
    margin-left: 41px;
  }
  .p9-grid {
    margin-right: 38px;
    margin-left: 11px;
    -webkit-box-pack: stretch;
    -webkit-justify-content: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    justify-items: stretch;
    grid-column-gap: 10px;
    -ms-grid-columns: minmax(120px, 3fr) minmax(120px, 3fr);
    grid-template-columns: minmax(120px, 3fr) minmax(120px, 3fr);
  }
  .p9-left-contact-block {
    overflow: auto;
    width: 95vw;
    margin-right: 0px;
    margin-bottom: 1px;
    margin-left: 8px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .text-block-6 {
    text-align: left;
  }
  .p6-text-block-6 {
    text-align: left;
  }
  .p9-featured-title {
    font-size: 26px;
    line-height: 30px;
    text-align: left;
  }
  .p10-cart-block-copy {
    padding-right: 0px;
    padding-left: 0px;
  }
  .p10-checkout-title-copy {
    font-size: 22px;
  }
  .p10-order-item-copy {
    margin-bottom: 19px;
  }
  .p10-checkout-title {
    font-size: 22px;
  }
  .p10-product-text {
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 10px;
  }
  .p10-product-text-block {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .p10-product-item-price {
    margin-top: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .p10-item-price-block {
    top: -3px;
    margin-top: -7px;
    padding-right: 5px;
    padding-left: 5px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p10-remove-item {
    padding-right: 15px;
  }

  .p10-product-quantity-dropdown {
    margin-top: 47px;
    margin-left: -70px;
  }

  .p12-left-wrapper {
    margin-right: 0px;
  }
  .p12-product-summary-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p12-product-summary-text-block {
    margin-top: 12px;
    margin-left: 0px;
  }
  .image-16 {
    width: 100%;
    height: auto;
  }
  .p12-snippet-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p12-snippet-button {
    margin-top: 9px;
    margin-left: 0px;
  }
  .grid-2 {
    -ms-grid-columns: minmax(180px, 0.25fr);
    grid-template-columns: minmax(180px, 0.25fr);
  }
  .p12-grid-2-copy {
    -ms-grid-columns: minmax(180px, 0.25fr);
    grid-template-columns: minmax(180px, 0.25fr);
  }
  .p13-grid {
    justify-items: center;
    -ms-grid-columns: minmax(140px, 0.25fr) minmax(140px, 0.25fr);
    grid-template-columns: minmax(140px, 0.25fr) minmax(140px, 0.25fr);
  }
  .p13-left-wrapper {
    margin-right: 0px;
  }
  .p13-top-snippet-button {
    margin-top: 9px;
    margin-left: 0px;
  }
  .p13-mid-snippet-button {
    margin-top: 9px;
    margin-left: 0px;
  }
  .p13-mid-snippet-text {
    padding-right: 10px;
    padding-left: 10px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-align: center;
  }
  .p13-top-snippet-text {
    padding-right: 10px;
    padding-left: 10px;
  }
  .p13-left-wrapper-title {
    padding-left: 2px;
    font-size: 18px;
    text-align: left;
  }
  .p13-whatsapp-message-field {
    font-size: 12px;
    width: 82.5% !important;
  }
  .p13-underchat-wrapper {
    margin-top: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p13-statement-block {
    width: auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p13-job-block {
    margin-top: 15px;
    margin-left: 0px;
  }
  .p13-statement-text {
    width: 100%;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .p13-job-form-field {
    font-size: 12px;
  }
  .p13-circle-grid {
    -ms-grid-columns: 0.25fr 0.25fr;
    grid-template-columns: 0.25fr 0.25fr;
  }
  .p13-confidence-lower-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p13-conf-lower-picture {
    width: 90%;
    padding-bottom: 102px;
  }
  .p13-conf-lower-text-line-1 {
    padding-bottom: 23.5px;
    text-align: center;
  }
  .p13-conf-lower-text-line-2 {
    margin-top: -25px;
    padding-bottom: 0px;
    text-align: center;
  }
  .p13-recently-viewed-grid {
    -ms-grid-columns: minmax(140px, 0.25fr) minmax(140px, 0.25fr);
    grid-template-columns: minmax(140px, 0.25fr) minmax(140px, 0.25fr);
  }
  .p13-recently-viewed-title {
    margin-top: -60px;
    font-size: 18px;
  }
  .p13-side-blog-grid {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    -ms-grid-columns: minmax(260px, 0.25fr);
    grid-template-columns: minmax(260px, 0.25fr);
  }
  .p14-blog-title-block {
    width: 100%;
    margin-top: 0px;
  }
  .p14-blog-title {
    padding-top: 30px;
    font-size: 24px;
    line-height: 30px;
  }
  .p14-blog-body-paragraph {
    width: 100%;
  }
  .p14-recent-posts-grid-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .p14-recent-post-grid {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    -ms-grid-columns: minmax(260px, 0.25fr);
    grid-template-columns: minmax(260px, 0.25fr);
  }
  .bold-text {
    margin-left: 15px;
  }
  .p20-form-content {
    padding-right: 0px;
    padding-left: 0px;
  }
  .p20-form-title {
    font-size: 22px;
  }
  .p21-main-body {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p21-category-wrapper {
    margin-top: 10px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p21-left-wrapper {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .p21---side-bar.seperator {
    max-width: none;
    min-width: auto;
    border-right-style: none;
  }
  .p21---sidebar-block {
    margin-left: 50px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .p21-cat-title {
    display: none;
  }
  .p21-left-menu {
    overflow: auto;
    width: 95vw;
    margin-right: 0px;
    margin-bottom: 1px;
    margin-left: 8px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .p21-right-wrapper {
    margin-left: -10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .p21-inner-content-block {
    margin-right: 0px;
    margin-left: 10px;
  }
  .p21-field-title {
    min-width: 100px;
  }
  .p21-form-wrapper {
    max-width: 300px;
  }
  .p21-right-wrapper-title {
    margin-left: 0px;
  }
  .p22-inner-content-block {
    margin-right: 0px;
    margin-left: 0px;
  }
  .p22-table-title-wrapper {
    display: none;
  }
  .p22-column-title-block {
    border-top: 1px solid #000;
  }
  .p22-order-title-block {
    border-left: 1px solid #000;
  }
  .p22-order-date-block {
    border-left: 1px solid #000;
  }
  .p22-link-block {
    border-left: 1px solid #000;
  }
  .p22-row-wrapper {
    margin-top: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p22-order-ref {
    min-width: 280px;
  }
  .p22-filter-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .p22-date-filter-button {
    margin-top: 10px;
  }
  .p22-right-wrapper {
    margin-right: 0px;
  }
  .p21-account-nav-mobile {
    margin-left: 20px;
  }
  .dropdown-toggle-4 {
    max-width: 280px;
    min-width: 280px;
    margin-left: 0px;
  }
  .p22-account-nav-mobile {
    margin-left: 0px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .p22-dropdown-toggle {
    max-width: 280px;
    min-width: 280px;
    margin-left: 10px;
  }
  .p23-right-wrapper {
    margin-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .p23-title-wrapper {
    display: block;
    background-color: #e2e2e2;
  }
  .p23-row-wrapper {
    margin-top: 13px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p23-title-column-block {
    border-top-style: solid;
  }
  .p23-inner-grid-labels {
    min-width: 280px;
  }
  .p23-bill---address-line-1-field {
    border-left-style: solid;
  }
  .p23-bill---address-line-2-field {
    border-left-style: solid;
  }
  .p23-bill---town-field {
    border-left-style: solid;
  }
  .p23-bill---county-field {
    border-left-style: solid;
  }
  .p23-bill---postal-code-field {
    border-left-style: solid;
  }
  .p23-bill---country-field {
    border-left-style: solid;
  }
  .p23-ship---address-line-1-field-copy {
    border-left-style: solid;
  }
  .p23-ship---address-line-2-field {
    border-left-style: solid;
  }
  .p23-ship---town-field {
    border-left-style: solid;
  }
  .p23-ship---postal-code-field {
    border-left-style: solid;
  }
  .p23-ship---country-field {
    border-left-style: solid;
  }
  .p23-ship---county-field {
    border-left-style: solid;
  }
  .p24-form {
    min-width: 270px;
    margin-left: 0px;
  }
  .p24-modal-block {
    width: 90%;
  }
  .p25-form {
    min-width: 270px;
    margin-left: 0px;
  }
  .p25-historical-uploads-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p25-table-title-wrapper {
    display: none;
  }
  .p25-ref {
    min-width: 280px;
  }
  .p25-row-wrapper {
    margin-top: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p25-upper-row-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p25-right-wrapper {
    margin-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .p26-product-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: none;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 10px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .p26-product-sku {
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 10px;
  }
  /*.p26-price-quantity-dropdown {*/
  /*  overflow: hidden;*/
  /*  max-width: 190px;*/
  /*  min-width: 190px;*/
  /*  margin-top: 7px;*/
  /*  margin-right: 50px;*/
  /*  margin-left: 10px;*/
  /*} commented by mehak and merged under single tag as multiple were in use*/
  .p26-dropdown-toggle {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p26-watched-item-wrapper {
    margin-bottom: 19px;
    padding-top: 19px;
  }
  .p26-product-info-block {
    width: 200px;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .p26-button-block {
    margin-top: 8px;
  }
  .p26-price-block {
    margin-top: 4px;
  }
  .p26-additional-option-dropdown {
    min-width: 190px;
    margin-top: 3px;
    margin-right: 50px;
    margin-left: 10px;
  }
  .p26-pagination-wrapper {
    max-width: 280px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    line-height: 25px;
  }
  .p1---save-to-watch-list-button {
    margin-left: 0px;
  }
  .p27-left-checkout-block {
    margin-right: 0px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .p27-right-checkout-block {
    width: 300px;
    margin-right: 0px;
  }
  .p27-option-block {
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p27-product-title {
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 10px;
  }
  .p27-product-sku {
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 10px;
  }
  .p27-additional-option-dropdown {
    margin-top: 3px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .p27-price-dropdown {
    margin-top: 4px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .p27-product-info-block {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .p27-dropdown-text {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p27-checkout-title {
    font-size: 22px;
  }
  .p27-selection-title {
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 10px;
  }
  .p27-title-wrapper {
    margin-bottom: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .p27-next-step {
    margin-top: 5px;
    margin-left: 0px;
    font-size: 14px;
  }
  .p5-right-bar-title {
    font-size: 22px;
  }
  .icon-3 {
    margin-right: 10px;
  }
  .icon-4 {
    margin-right: 10px;
  }
  .p27-load-list-block {
    margin-top: 10px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .p27-load-list-field {
    width: auto;
  }
  .p27-cart-ref {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .p28-page-title-wrapper {
    margin-right: 0px;
    margin-left: 0px;
  }

  .p28-h1 {
    font-size: 35px;
    line-height: 38px;
  }

  .p28-content-wrapper-1 {
    margin-right: 10px;
    margin-left: 10px;
  }

  .p28-content-wrapper-2 {
    margin-right: 10px;
    margin-left: 10px;
  }

  .p28-c2-wrapper {
    margin-right: 10px;
    margin-left: 10px;
  }

  .p28-content-wrapper-3 {
    margin-right: 10px;
    margin-left: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p28-c3-text-wrapper {
    margin-right: 0px;
  }

  .p28-accent-text-c3 {
    text-align: center;
  }

  .p28-dropdown-wrapper {
    margin-right: 0px;
  }

  .p28-dropdown-toggle {
    min-width: 90vw;
  }

  .p28-c3-text-2 {
    text-align: center;
  }

  .p28-c3-block-1-text-wrapper {
    margin-right: 10px;
    margin-left: 10px;
  }

  .p28-content-wrapper-4 {
    margin-right: 10px;
    margin-left: 10px;
  }

  .p28-content-wrapper-5 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .p28-faq {
    margin-right: 0px;
    margin-left: 0px;
  }

  .p28-tabs-menu {
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
    padding-left: 0px;
  }

  .p28-tab-link-tab-5.w--current {
    padding-right: 0px;
    padding-left: 0px;
  }

  .p28-content-wrapper-7 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .p28-c7-comparison-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p28-c7-left-wrapper {
    margin-right: 10px;
    margin-left: 10px;
  }

  .p28-c7-right-wrapper {
    margin-right: 10px;
    margin-left: 10px;
  }

  .p28-comparison-text {
    text-align: left;
  }

  .p28-form-block {
    padding-right: 0px;
  }

  .p28-form-block-2 {
    width: auto;
    margin-left: 0px;
  }

  .p28-form-field {
    width: 280px;
  }

  .p28-c8-title {
    width: 90%;
  }

  .p28-recommended-product-text {
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
  }

  .p28-product-frame {
    margin-bottom: 20px;
  }

  .p28-cookie-block {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 300px;
    margin-right: 0px;
    margin-left: 10px;
  }

  .p28-cookie-modal-title {
    margin-top: 10px;
  }

  .p28-cookie-description {
    margin-bottom: 10px;
  }

  .p28-cookie-accept-button {
    margin-bottom: 10px;
  }

  .p29-content-wrapper-2 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .p29-c2-wrapper {
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .p29-content-wrapper-1 {
    margin-right: 10px;
    margin-left: 10px;
  }

  .p29-snippet-block {
    margin-right: 0px;
  }

  .p29-recommended-wrapper-copy {
    display: none;
  }

  .p29-content-wrapper-3 {
    margin-right: 10px;
    margin-bottom: 36px;
    margin-left: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p29-c3-title {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }

  .p29-c3-subtitle {
    text-align: center;
  }

  .p29-c3-custom-print-button {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 18px;
  }

  .bold-text-5 {
    font-size: 30px;
    line-height: 30px;
  }

  .p29-content-wrapper {
    margin-right: 0px;
    margin-left: 0px;
  }

  .p29-c4-title {
    margin-top: 0px;
    font-size: 24px;
    line-height: 30px;
  }

  .p29---recommended-products-block-copy {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .p29-mobile-link-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p29-mobile-links {
    width: auto;
    min-width: 300px;
    margin-top: 15px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 18px;
    text-align: center;
  }

  .p29-mobile-snippet {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .p5-import-field-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .p5-import-vat-block {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .p5-import-licence-block {
    margin-left: 0px;
  }

  .p5-sign-in-button {
    margin-left: 0px;
    text-align: center;
  }

  .p5-login-right-block {
    margin-left: 0px;
  }

  .p5-login-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .p5-payment-option-wrapper {
    padding-bottom: 10px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p31-content-wrapper-3 {
    margin-right: 10px;
    margin-left: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p31-c3-text-wrapper {
    margin-right: 0px;
  }

  .p31-c3-block-1-text-wrapper {
    margin-right: 10px;
    margin-left: 10px;
  }

  .p31-accent-text-c3 {
    text-align: center;
  }

  .p31-c3-text-2 {
    text-align: center;
  }

  .p31-right-wrapper {
    margin-left: 0px;
  }

  .p31-custom-quote-button {
    margin-left: 5px;
  }

  .p31-product-image {
    width: 250px;
    height: 300px;
  }

  .p31-ink-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p31-ink-row-1 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .p31-ink-row-2 {
    display: none;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .p31-bright-green {
    margin-bottom: 7px;
  }

  .p31-green {
    display: block;
    margin-bottom: 7px;
  }

  .p31-azure-blue {
    display: block;
  }

  .p31-blue {
    display: block;
  }

  .p31-violet {
    display: block;
  }

  .p31-black {
    display: block;
  }

  .p31-white {
    margin-right: 7px;
  }

  .p31-h1 {
    font-size: 35px;
    line-height: 38px;
  }

  .p31-c4-title {
    margin-top: 0px;
    font-size: 24px;
    line-height: 30px;
  }

  .p31-green-row-2 {
    margin-bottom: 7px;
  }
  .p1-request-sample-modal {
    padding-top: 18px;
  }

  .p1-sample-modal-block {
    width: 300px;
  }

  .p29-product-grid {
    display: block;
    padding-top: 32px;
  }

  .p29-product-frame {
    margin-bottom: 20px;
  }

  .p29-grid-title {
    padding-right: 10px;
    padding-left: 10px;
  }

  .p29-stock-text {
    padding-bottom: 5px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  .p29-grid-accent-text {
    padding-right: 10px;
    padding-left: 10px;
  }

  .p29-actual-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .image-22 {
    margin-top: 11px;
    margin-bottom: 11px;
  }

  .p28-price-match-div {
    display: block;
    text-align: center;
  }

  .p28---cart-sample-wrapper-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .p28-free-sample-button {
    margin-top: 10px;
    margin-left: 0px;
  }

  .p28-call-now-div {
    display: none;
  }

  .p28-accent-text-c4 {
    padding-right: 0px;
    text-align: center;
  }

  .p28-accent-text-c5 {
    text-align: center;
  }

  .p28-pouch-modal-block {
    width: 300px;
  }

  .p28-dropdown-wrapper-2 {
    margin-right: 0px;
  }

  .p28-content-wrapper-3-2 {
    margin-right: -10px;
    margin-left: -10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .lp-p1-product-frame {
    margin-right: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .l1-p1-page-title-wrapper {
    margin-right: 0px;
    margin-left: 0px;
  }

  .lp-p1-h3 {
    line-height: 24px;
  }

  .lp-p1-pouchshop-grid {
    margin-right: 0px;
    margin-left: 0px;
  }

  .lp-p1-title {
    line-height: 30px;
  }

  .lp-p-h1 {
    font-size: 35px;
    line-height: 38px;
  }
  .p31-enquiry-form {
    width: 300px;
  }

  .lp-p2h1-copy {
    min-width: 0px;
    font-size: 25px;
    line-height: 30px;
  }

  .l1-p2-page-title-wrapper-copy {
    margin-right: 0px;
    margin-left: 0px;
  }

  .lp-p2-text-copy {
    display: none;
  }

  .p33-header-wrapper {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    background-image: none;
  }

  .p33-title-wrapper {
    max-width: 300px;
    margin-right: 10px;
    margin-left: 10px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .p33-wrapper {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c1-copy {
    max-width: 300px;
  }

  .p33-c3 {
    max-width: 300px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c4 {
    max-width: 300px;
  }

  .p33-c4-card {
    margin-right: 0px;
    margin-left: 0px;
  }

  .p33-main-body {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .p33-c4-h2 {
    font-size: 35px;
    line-height: 38px;
  }

  .p33-c3-right-wrapper {
    margin-left: 0px;
  }

  .p33-c3-image {
    width: 300px;
    height: 300px;
  }

  .p33-c3-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 35px;
    line-height: 38px;
    text-align: center;
  }

  .p33-c2-right-wrapper {
    max-width: 300px;
  }

  .p33-c2-h2 {
    max-width: 300px;
    font-size: 35px;
    line-height: 38px;
  }

  .p33-c1-h2 {
    font-size: 35px;
    line-height: 38px;
  }

  .p33-c5-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c5-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .text-span-6 {
    text-align: center;
  }

  .p33-c5-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c5-card-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c6-right {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p33-c7-form-field {
    width: 280px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .p33-c7-form-button {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    text-align: center;
  }

  .p33-c7-a {
    padding-right: 10px;
    padding-left: 10px;
  }

  .p33-c7-process {
    margin-left: 0px;
  }

  .p33-c7-h4 {
    font-size: 35px;
    line-height: 38px;
  }

  .grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .p33-c3-image2 {
    width: 300px;
    height: 225px;
  }

  .p34-c1-h3 {
    max-width: 300px;
    font-size: 35px;
    line-height: 38px;
  }

  .p34-form-c1 {
    min-width: 260px;
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .p34-form-field {
    min-width: 260px;
  }

  .p34-form-dropdown {
    min-width: 260px;
  }

  .p34-header-wrapper {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    background-image: none;
  }

  .p34-director-button-1 {
    float: right;
  }

  .p35-right-wrapper {
    margin-left: -10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p22-date-filter-button-copy {
    margin-top: 10px;
  }

  .p35-mobile-disclaimer {
    max-width: 280px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 20px;
  }
}

#w-node-cdfac0d9b5a5-b0c875b4 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: start;
  align-self: start;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-0db45023e19e-0d67f4ba {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-54eee580db75-225965cf {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-54eee580db91-225965cf {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-87729ebcdc70-07ca1e79 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-db4ea8a5a468-07ca1e79 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-0c6632524366-07ca1e79 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-4ae4d1943ecc-1a75c61d {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-4ebcbc5fad20-1a75c61d {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dc651c2fbc52-1a75c61d {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-3b2887f67f60-1a75c61d {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2059-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2060-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2067-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc206e-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2075-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc207c-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2083-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc208a-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2091-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2098-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc209f-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc20a6-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc20ad-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc20b4-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc20bb-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc20c2-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc20c9-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc20d0-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc20d7-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc20de-1b0014bb {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_4daa3684-3864-6223-2038-54eee580db75-21389b37 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2059-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2060-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2067-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc206e-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2075-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc207c-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2083-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc208a-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2091-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2098-6627ec85 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}


@media screen and (max-width: 991px) {
  #w-node-cdfac0d9b5af-b0c875b4 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  #w-node-0db45023e19e-0d67f4ba {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
  #w-node-0db45023e1a8-0d67f4ba {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }
  #w-node-54eee580db75-225965cf {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }
  #w-node-54eee580db91-225965cf {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-54eee580db91-225965cf {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
}


@media screen and (max-width: 767px) {
  #w-node-54eee580db91-225965cf {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
}

.p5-discount-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 10px;
}

.p5-discount-field {
  margin-right: 5px;
  margin-bottom: 0px;
}

.p5-discount-button {
  margin-bottom: 10px;
  background-color: #000;
}

/*custom file upload css done by mehak ----important*/

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select file';
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: none;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.margin_left{
  margin-left: 10px;
}
